import {
  Airlines,
  BorderColor,
  CalendarToday,
  Flight,
  Room,
  SdCard,
  Timelapse,
  TravelExplore,
} from '@mui/icons-material';

export const StartIcon = CalendarToday;
export const FlightIcon = Flight;
export const SizeIcon = SdCard;
export const CoverageIcon = TravelExplore;
export const DurationIcon = Timelapse;
export const DataPointIcon = Room;
export const DrawnPointIcon = BorderColor;
export const AirlineIcon = Airlines;
