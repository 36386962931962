import { NavigateNext, TipsAndUpdates } from '@mui/icons-material';
import { Alert, AlertProps, AlertTitle, IconButton } from '@mui/material';
import { useCallback, useState } from 'react';
import { TrailLengthIcon } from '../Player/icons';

export const Tip: React.FC<AlertProps> = function Tip({ children, ...props }) {
  const [currentTipIdx, setCurrentTipIdx] = useState(
    Math.floor(Math.random() * TIPS.length),
  );

  const nextTip = useCallback(() => {
    setCurrentTipIdx((prev) => (prev + 1) % TIPS.length);
  }, [setCurrentTipIdx]);

  const tip = TIPS[currentTipIdx];

  return (
    <Alert
      icon={<TipsAndUpdates fontSize="inherit" />}
      action={
        <IconButton
          onClick={() => {
            nextTip();
          }}
        >
          <NavigateNext />
        </IconButton>
      }
      {...props}
    >
      <AlertTitle>Tip</AlertTitle>
      {tip.content}
    </Alert>
  );
};

const TIPS = [
  {
    content:
      'Tilt the map using three fingers or by pressing the shift key while dragging.',
  },
  {
    content: (
      <>
        Some color presets are available !<br />
        Check the color settings !
      </>
    ),
  },
  {
    content: (
      <>
        You can change the "trail" length of each flight.
        <br />
        Click on the <TrailLengthIcon fontSize="inherit" /> button on the bottom
        left to see more.
      </>
    ),
  },
];
