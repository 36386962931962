import { useEffect, useCallback } from 'react';

import {
  AppBar,
  IconButton,
  Fab,
  Box,
  Stack,
  useTheme,
  useMediaQuery,
  Paper,
} from '@mui/material';

import { SkipNext, SkipPrevious, PlayArrow, Pause } from '@mui/icons-material';
import { useDatasetLoader } from '../Loader';

import { PlaybackControlsSecondary } from './PlaybackControlSecondary';
import { ShareButton } from './ShareButton';
import { MapKey } from './MapKey';
import { useIsMobile } from '../utils';
import { useAnalytics } from '../analytics';
import { useSetTimeOffset, usePlayback } from './hooks';
import { PlaybackProgress } from './PlaybackProgress';
import { PlaybackMainLoop } from './PlaybackMainLoop';
import { PlayerTime } from './PlayerTime';
import { TinyText } from './TinyText';

export const PlayerControlMainPanel: React.FC<{}> =
  function PlayerControlMainPanel() {
    const setTimeOffset = useSetTimeOffset();
    const { mediaQuery: isMobile } = useIsMobile();
    const [analytics] = useAnalytics();

    const [playerState, { setPlayerState }] = usePlayback();

    const [{ metadata: datasetMetadata, loading, error }] = useDatasetLoader();

    /**
     * When the dataset is loading, stop playing.
     *
     * TODO: When the dataset is loaded, reset player state
     */
    useEffect(() => {
      if (loading) {
        setPlayerState((prev) => ({ ...prev, playing: false }));
        return;
      }
    }, [loading, setPlayerState]);

    const loopLength = datasetMetadata?.duration ?? 3600 * 24;

    const isDataLoading = loading || !!error;

    const skipBack = useCallback(() => {
      analytics.event('player_skip_back', {
        animation_speed: playerState.animationSpeed,
      });
      setTimeOffset(0);
    }, [analytics, setTimeOffset, playerState.animationSpeed]);

    const skipForward = useCallback(() => {
      analytics.event('player_skip_forward', {
        animation_speed: playerState.animationSpeed,
      });

      setTimeOffset((prev) => {
        /**
         * Loop length is usually a day and that's 60 * 60 * 24 seconds.
         *
         * When skipping forward, we need to go to the next time offset on 10 minutes intervals.
         *
         * For instance, if we're at 15:03:36, we need to jump to 15:10:00.
         */

        let step = 60 * 10; // 10 minutes
        if (playerState.animationSpeed > 10) {
          step = 30 * 60;
        }

        const currentOffset = Math.floor(prev / step) * step;
        const nextOffset = currentOffset + step;

        return Math.min(loopLength - 1, nextOffset);
      });
    }, [analytics, setTimeOffset, playerState.animationSpeed, loopLength]);

    return (
      <>
        <PlaybackMainLoop />

        <PlayerContainer>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'center',
              margin: 1,
              flexGrow: 1,
              flexShrink: 1,
              minWidth: 0,
              maxWidth: '100%',
            }}
          >
            <MapKey disabled={isDataLoading} />
            <PlaybackProgress disabled={isDataLoading} />
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                alignItems: 'center',
              }}
            >
              <TinyText
                sx={{
                  gridRow: 1,
                  pl: 2,
                  gridColumn: 1,
                  mt: -1,
                  justifySelf: 'start',
                  alignSelf: 'start',
                }}
              >
                {datasetMetadata && dateToDateStringUTC(datasetMetadata.start)}
              </TinyText>
              <Stack
                sx={{
                  gridRow: '1 / span 2',
                  gridColumn: 2,
                  justifySelf: 'center',
                  alignSelf: 'center',
                }}
                spacing={1}
                justifyContent="center"
                alignItems="center"
                direction="row"
              >
                <IconButton
                  disabled={isDataLoading}
                  size={isMobile ? 'medium' : 'large'}
                  onClick={skipBack}
                >
                  <SkipPrevious />
                </IconButton>
                <Fab
                  color={playerState.playing ? 'secondary' : 'default'}
                  disabled={isDataLoading}
                  onClick={() => {
                    analytics.event(
                      playerState.playing ? 'player_pause' : 'player_play',
                      {
                        animation_speed: playerState.animationSpeed,
                      },
                    );
                    setPlayerState((prev) => ({
                      ...prev,
                      playing: !prev.playing,
                    }));
                  }}
                  size={isMobile ? 'medium' : 'large'}
                >
                  {playerState.playing ? <Pause /> : <PlayArrow />}
                </Fab>
                <IconButton
                  disabled={isDataLoading}
                  size={isMobile ? 'medium' : 'large'}
                  onClick={skipForward}
                >
                  <SkipNext />
                </IconButton>
              </Stack>
              <PlayerTime />

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignSelf: 'end',
                  gridRow: 2,
                  gridColumn: 1,
                  alignItems: 'start',
                  justifySelf: 'start',
                }}
              >
                <PlaybackControlsSecondary disabled={isDataLoading} />
              </Box>
              <Box
                sx={{
                  gridRow: 2,
                  gridColumn: 3,
                  display: 'flex',
                  flexDirection: 'row',
                  alignSelf: 'end',
                  justifySelf: 'end',
                  alignItems: 'end',
                }}
              >
                <ShareButton disabled={isDataLoading} />
              </Box>
            </Box>
          </Box>
        </PlayerContainer>
      </>
    );
  };

const PlayerContainer: React.FC<{}> = function PlayerContainer({ children }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  if (!isDesktop) {
    return (
      <AppBar
        elevation={0}
        position="sticky"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          bottom: 0,
          flexShrink: 1,
          maxWidth: '100vw',
          minWidth: 0,
        }}
      >
        {children}
      </AppBar>
    );
  }

  return (
    <Paper
      variant="outlined"
      sx={{
        display: 'flex',
        position: 'absolute',
        bottom: theme.spacing(1),
        width: '50%',
        maxWidth: '50%',
        left: '50%',
        transform: 'translate(-50%, 0)',
      }}
    >
      {children}
    </Paper>
  );
};

function dateToDateStringUTC(date: Date): string {
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
}
