import { useCallback, useEffect, useMemo, useState } from 'react';
import { tuple } from '../utils';
import { getGPUTier, TierResult } from 'detect-gpu';

export const PERFORMANCE_PRESETS = {
  ultralow: {
    targetPlots: 250_000,
  },
  low: {
    targetPlots: 500_000,
  },
  medium: {
    targetPlots: 1_000_000,
  },
  high: {
    targetPlots: 2_000_000,
  },
  ultrahigh: {
    targetPlots: 5_000_000,
  },
  extreme: {
    targetPlots: Infinity,
  },
};

export type PerformancePresetName = keyof typeof PERFORMANCE_PRESETS;

export function useQualitySettings({
  datasetPlots,
  qualityPreset,
}: {
  datasetPlots?: number;
  qualityPreset?: null | PerformancePresetName;
} = {}) {
  const [detectionResults, setDetectionResults] = useState<
    null | TierResult | Error
  >(null);

  const recommendedPreset: PerformancePresetName = useMemo(() => {
    if (
      !detectionResults ||
      detectionResults instanceof Error ||
      !detectionResults.fps
    ) {
      return 'medium';
    }

    if (detectionResults.isMobile) {
      if (detectionResults.tier === 3) {
        return 'low';
      }

      return 'ultralow';
    }

    if (detectionResults.tier === 2) {
      return 'medium';
    }

    if (detectionResults.tier < 2) {
      return 'low';
    }

    if (detectionResults.tier === 3) {
      if (!detectionResults.fps) {
        return 'medium';
      }

      if (detectionResults.fps > 200) {
        return 'ultrahigh';
      }

      if (detectionResults.fps > 100) {
        return 'high';
      }

      return 'medium';
    }

    return 'medium';
  }, [detectionResults]);

  const getSampleRateForData = useCallback(
    (datums: number) => {
      const preset = qualityPreset ?? recommendedPreset;

      const performancePreset = PERFORMANCE_PRESETS[preset];
      if (performancePreset.targetPlots === Infinity) {
        return 1;
      }

      return Math.floor(datums / performancePreset.targetPlots) + 1;
    },
    [qualityPreset, recommendedPreset],
  );

  const targetSampleRate = useMemo(() => {
    if (datasetPlots === undefined) {
      return null;
    }
    return getSampleRateForData(datasetPlots);
  }, [getSampleRateForData, datasetPlots]);

  const runDetection = useCallback(async function detectGPU() {
    // setDetectionResults(null);
    try {
      const res = await getGPUTier();
      console.log('Detected GPU', res);
      setDetectionResults({ ...res });
    } catch (err: any) {
      console.log('Error detecting GPU', err);
      setDetectionResults(err);
    }
  }, []);

  useEffect(() => {
    runDetection();
  }, [runDetection]);

  const r = useMemo(
    () =>
      tuple(
        {
          targetSampleRate,
          loading: detectionResults === null,
          error: detectionResults instanceof Error ? detectionResults : null,
          recommendedPreset,
          detectionResults,
        },
        { runDetection, getSampleRateForData },
      ),
    [
      detectionResults,
      targetSampleRate,
      recommendedPreset,
      runDetection,
      getSampleRateForData,
    ],
  );

  return r;
}
