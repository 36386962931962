import { v4 } from 'uuid';
import { RGBAColor } from '.';
import { FLAT_UI_COLORS, TRIP_OPACITY } from './palette';
import type { ColorFilterState } from './types';
import { createCatchAllFilter, createColorPalette } from './utils';

function createAircraftOperatorPalette(
  items: Array<{ name: string; prefixes: Array<string> }>,
  {
    offset = 0,
    catchAllEnabled = true,
  }: { offset?: number; catchAllEnabled?: boolean } = {},
): Array<ColorFilterState> {
  const palette = createColorPalette({ count: items.length, offset });

  return [
    ...items.map((p, idx) => ({
      type: 'aircraftOperator' as const,
      id: v4(),
      displayName: p.name,
      aircraftOperators: p.prefixes,
      color: palette[idx],
      enabled: true,
    })),
    createCatchAllFilter('muted', { enabled: catchAllEnabled }),
  ];
}

// https://stackoverflow.com/a/57103940/194685
type DistributiveOptional<T, K extends keyof T> = T extends any
  ? Omit<T, K> & Partial<Pick<T, K>>
  : never;

function createPalette(
  items: Array<
    DistributiveOptional<ColorFilterState, 'id' | 'color' | 'enabled'>
  >,
  {
    offset = 0,
    catchAll = true,
    opacity,
    catchAllEnabled = true,
  }: {
    offset?: number;
    catchAll?: boolean;
    opacity?: number;
    catchAllEnabled?: boolean;
  } = {},
): Array<ColorFilterState> {
  const palette = createColorPalette({ count: items.length, offset, opacity });

  const r: Array<ColorFilterState> = items.map((p, idx) => ({
    id: v4(),
    color: palette[idx],
    enabled: true,
    ...p,
  }));

  if (catchAll) {
    r.push(createCatchAllFilter('muted', { enabled: catchAllEnabled }));
  }

  return r;
}

const ODD_FL_COLOR: RGBAColor = [...FLAT_UI_COLORS.alizarin, TRIP_OPACITY / 4];
const EVEN_FL_COLOR: RGBAColor = [...FLAT_UI_COLORS.emerland, TRIP_OPACITY / 4];

export const PRESETS = [
  {
    title: 'EU flag carriers',
    description: <>Major historical european airlines</>,
    source: {
      name: 'Wikipedia',
      href: 'https://en.wikipedia.org/wiki/Flag_carrier',
    },
    filters: createAircraftOperatorPalette(
      [
        { name: 'Air France', prefixes: ['AFR'] },
        { name: 'British Airways', prefixes: ['BAW'] },
        { name: 'Lufthansa', prefixes: ['DLH'] },
        { name: 'Swiss', prefixes: ['SWR'] },
        { name: 'KLM', prefixes: ['KLM'] },
        { name: 'ITA', prefixes: ['ITY'] },
        { name: 'Iberia', prefixes: ['IBE'] },
      ],
      { catchAllEnabled: false },
    ),
  },
  {
    title: 'Low altitude highlight',
    description: (
      <>
        This preset will only show aircraft flying low. Great to explore airport
        activity !
      </>
    ),
    filters: createPalette(
      [
        {
          type: 'altitudeGradient',
          displayName: 'Ground to 30,000ft',
          color: [...FLAT_UI_COLORS.greensea, TRIP_OPACITY / 4],
          topColor: [...FLAT_UI_COLORS.greensea, TRIP_OPACITY / 4],
          bottomColor: [...FLAT_UI_COLORS.carrot, 0xff],
          topFt: 30000,
          bottomFt: 0,
          aboveMode: 'passthrough',
          belowMode: 'clamp',
        },
      ],
      { catchAll: true, catchAllEnabled: false },
    ),
  },

  {
    title: 'Vertical speed',
    description: (
      <>
        This preset will color tracks depending on the current vertical speed of
        the aircraft.
      </>
    ),
    filters: createPalette(
      [
        {
          type: 'verticalSpeed',
          displayName: 'Vertical Speed',
          color: [0xff, 0xff, 0xff, TRIP_OPACITY / 4],
          climbColor: [...FLAT_UI_COLORS.peterriver, TRIP_OPACITY],
          descentColor: [...FLAT_UI_COLORS.carrot, TRIP_OPACITY],
        },
      ],
      { catchAll: false },
    ),
  },
  {
    title: 'Cruising flight levels',
    description: (
      <>
        Green for even flight levels (FL380, FL360, FL340), red for even flight
        levels (FL390, FL370, FL350, FL330). Best viewed with a long trail
        length.
      </>
    ),
    filters: createPalette(
      [
        {
          type: 'altitudeGradient',
          displayName: 'FL340',
          color: EVEN_FL_COLOR,
          topColor: EVEN_FL_COLOR,
          bottomColor: EVEN_FL_COLOR,
          topFt: 34500,
          bottomFt: 33500,
          aboveMode: 'passthrough',
          belowMode: 'passthrough',
        },
        {
          type: 'altitudeGradient',
          displayName: 'FL360',
          color: EVEN_FL_COLOR,
          topColor: EVEN_FL_COLOR,
          bottomColor: EVEN_FL_COLOR,
          topFt: 36500,
          bottomFt: 35500,
          aboveMode: 'passthrough',
          belowMode: 'passthrough',
        },
        {
          type: 'altitudeGradient',
          displayName: 'FL380',
          color: EVEN_FL_COLOR,
          topColor: EVEN_FL_COLOR,
          bottomColor: EVEN_FL_COLOR,
          topFt: 38500,
          bottomFt: 37500,
          aboveMode: 'passthrough',
          belowMode: 'passthrough',
        },
        {
          type: 'altitudeGradient',
          displayName: 'FL330',
          color: ODD_FL_COLOR,
          topColor: ODD_FL_COLOR,
          bottomColor: ODD_FL_COLOR,
          topFt: 33500,
          bottomFt: 32500,
          aboveMode: 'passthrough',
          belowMode: 'passthrough',
        },
        {
          type: 'altitudeGradient',
          displayName: 'FL350',
          color: ODD_FL_COLOR,
          topColor: ODD_FL_COLOR,
          bottomColor: ODD_FL_COLOR,
          topFt: 35500,
          bottomFt: 34500,
          aboveMode: 'passthrough',
          belowMode: 'passthrough',
        },
        {
          type: 'altitudeGradient',
          displayName: 'FL370',
          color: ODD_FL_COLOR,
          topColor: ODD_FL_COLOR,
          bottomColor: ODD_FL_COLOR,
          topFt: 37500,
          bottomFt: 36500,
          aboveMode: 'passthrough',
          belowMode: 'passthrough',
        },
        {
          type: 'altitudeGradient',
          displayName: 'FL390',
          color: ODD_FL_COLOR,
          topColor: ODD_FL_COLOR,
          bottomColor: ODD_FL_COLOR,
          topFt: 39500,
          bottomFt: 38500,
          aboveMode: 'passthrough',
          belowMode: 'passthrough',
        },
      ],
      { catchAll: true, catchAllEnabled: false },
    ),
  },
  {
    title: 'Low cost EU airlines',
    description: 'Major low cost european airlines',
    source: {
      name: 'Wikipedia',
      href: 'https://en.wikipedia.org/wiki/List_of_low-cost_airlines#Europe',
    },
    filters: createAircraftOperatorPalette(
      [
        { name: 'EasyJet', prefixes: ['EZY', 'EZS', 'EJU'] },
        { name: 'Ryanair', prefixes: ['RYR', 'RUK'] },
        { name: 'Eurowings', prefixes: ['EWE', 'EWG'] },
        { name: 'Transavia', prefixes: ['TVF', 'TRA'] },
        { name: 'Wizz Air', prefixes: ['WZZ', 'WAZ', 'WUK'] },
        { name: 'Smart wings', prefixes: ['TVS', 'TVL', 'TVP', 'TVQ'] },
        { name: 'Volotea', prefixes: ['VOE'] },
        { name: 'Vueling', prefixes: ['VLG'] },
      ],
      { catchAllEnabled: false },
    ),
  },
  // {
  //   title: 'Paris Charles-de-Gaulle flights',
  //   filters: createPalette([
  //     {
  //       type: 'airport',
  //       displayName: 'Departures',
  //       airports: ['LFPG'],
  //       airportFilterMode: 'departures',
  //     },
  //     {
  //       type: 'airport',
  //       displayName: 'Arrivals',
  //       airports: ['LFPG'],
  //       airportFilterMode: 'arrivals',
  //     },
  //   ]),
  // },
  {
    title: 'Flag carriers vs low cost airlines',
    description: (
      <>Compare historical/flag carriers vs low cost european airlines</>
    ),
    filters: createAircraftOperatorPalette([
      {
        name: 'Major',
        prefixes: ['AFR', 'BAW', 'DLH', 'SWR', 'KLM', 'ITY', 'BEL', 'IBE'],
      },
      {
        name: 'Low cost',
        prefixes: [
          'EZY',
          'EZS',
          'EJU',
          'RYR',
          'RUK',
          'EWE',
          'EWG',
          'TVF',
          'TRA',
          'WZZ',
          'WAZ',
          'WUK',
          'TVS',
          'TVL',
          'TVP',
          'TVQ',
          'VOE',
          'VLG',
        ],
      },
    ]),
  },
  {
    title: 'Major US airlines',
    description: (
      <>
        Did you know that in 2019, 4 of the top 5 airlines by passangers carried
        were american ?
      </>
    ),
    filters: createAircraftOperatorPalette(
      [
        { name: 'American Airlines', prefixes: ['AAL'] },
        { name: 'Delta', prefixes: ['DAL'] },
        { name: 'Southwest', prefixes: ['SWA'] },
        { name: 'United', prefixes: ['UAL'] },
        { name: 'Alaska', prefixes: ['ASA'] },
        { name: 'JetBlue', prefixes: ['JBU'] },
      ],
      { catchAllEnabled: false },
    ),
    source: {
      name: 'Wikipedia',
      href: 'https://en.wikipedia.org/wiki/List_of_largest_airlines_in_North_America',
    },
  },
  // {
  //   title: 'North America countries',
  //   filters: createAircraftOperatorPalette(
  //     [
  //       {
  //         name: 'USA',
  //         prefixes: [
  //           'AAL',
  //           'DAL',
  //           'SWA',
  //           'UAL',
  //           'ASA',
  //           'JBU',
  //           'FFT',
  //           'AAY',
  //           'HAL',
  //           'SCX',
  //         ],
  //       },
  //       { name: 'Canada', prefixes: ['ACA', 'WJA'] },
  //       { name: 'Mexico', prefixes: ['VOI', 'AMX', 'AIJ', 'VIV'] },
  //     ],
  //     { offset: 4 },
  //   ),
  // },
  {
    title: 'Airlines per continent',
    description: (
      <>
        Major US airlines vs major EU airlines vs major asian airlines vs major
        middle-east airlines.
      </>
    ),
    source: {
      name: 'Wikipedia',
      href: 'https://en.wikipedia.org/wiki/Largest_airlines_in_the_world',
    },
    filters: createAircraftOperatorPalette(
      [
        {
          name: 'North America',
          prefixes: [
            'DAL',
            'AAL',
            'UAL',
            'SWA',
            'ACA',
            'ASA',
            'JBU',
            'AMX',
            'VOI',
          ],
        },
        {
          name: 'Europe',
          prefixes: [
            // Flag carriers
            'AFR',
            'BAW',
            'DLH',
            'SWR',
            'KLM',
            'ITY',
            'BEL',
            'IBE',
            'AUA',
            'SAS',
            'TAP',
            // Low cost
            'EZY',
            'EZS',
            'EJU',
            'RYR',
            'RUK',
            'EWE',
            'EWG',
            'TVF',
            'TRA',
            'WZZ',
            'WAZ',
            'WUK',
            'TVS',
            'TVL',
            'TVP',
            'TVQ',
            'VOE',
            'VLG',
          ],
        },
        { name: 'Middle east', prefixes: ['UAE', 'QTR', 'SVA', 'THY'] },
        {
          name: 'Asia',
          prefixes: [
            'ANA',
            'JAL',
            'CSN',
            'CES',
            'CCA',
            'IGO',
            'AXM',
            'CHH',
            'LNI',
            'SIA',
            'CPA',
            'KAL',
            'THA',
            'AIC',
          ],
        },
      ],
      { catchAllEnabled: false },
    ),
  },
  {
    title: 'Cargo airlines',
    description: <>Compare major cargo airlines.</>,
    source: {
      href: 'https://en.wikipedia.org/wiki/Largest_airlines_in_the_world',
      name: 'Wikipedia',
    },
    filters: createAircraftOperatorPalette(
      [
        { name: 'FedEx', prefixes: ['FDX'] },
        { name: 'UPS', prefixes: ['UPS'] },
        { name: 'Lufthansa Carto', prefixes: ['GEC'] },
        { name: 'Cargolux', prefixes: ['CLX'] },
        { name: 'Air China Cargo', prefixes: ['CAO'] },
      ],
      { catchAllEnabled: false },
    ),
  },
  {
    title: 'Air France flights',
    description:
      'Air France flights with a 2 or 3 digit flight number on one side (e.g AFR008), the rest on the other side',
    source: {
      name: 'Wikipedia',
      href: 'https://en.wikipedia.org/wiki/List_of_low-cost_airlines#Europe',
    },
    filters: createPalette(
      [
        {
          type: 'callsign',
          displayName: 'Long haul',
          startsWith: 'AFR',
          maxLength: 6,
        },
        {
          type: 'callsign',
          displayName: 'Medium/short haul',
          startsWith: 'AFR',
          minLength: 7,
        },
      ],
      { offset: 2, catchAllEnabled: false },
    ),
  },
];
