import { FastForward } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { memo, useState } from 'react';
import { useAnalytics } from '../analytics';
import { useDatasetInformation } from '../Loader';
import { usePlayback } from './hooks';
import { TrailLengthIcon } from './icons';

export const PlaybackControlsSecondary: React.FC<{ disabled?: boolean }> = memo(
  function PlaybackControlsSecondary({ disabled = false }) {
    const [analytics] = useAnalytics();

    /**
     * This piece of state controls the state of the animation speed
     * control menu.
     */
    const [animationSpeedEl, setAnimationSpeedEl] =
      useState<null | HTMLElement>(null);

    /**
     * Dataset metadata/information
     */

    const { metadata } = useDatasetInformation();

    /**
     * Likewise, this one controls the state of the trail length
     * control menu.
     */
    const [trailLengthEl, setTrailLengthEl] = useState<null | HTMLElement>(
      null,
    );

    /**
     * This is the main piece of state that will be updated on each
     * animation loop.
     */
    const [playerState, { suspend, resume, setPlayerState }] = usePlayback();

    return (
      <>
        <Tooltip placement="top" title="Playback speed">
          <IconButton
            id="playback-speed-button"
            disabled={disabled}
            size="small"
            aria-controls="playback-speed-menu"
            aria-haspopup="true"
            onClick={(ev) => {
              suspend();
              setAnimationSpeedEl(ev.currentTarget);
            }}
          >
            <FastForward />
          </IconButton>
        </Tooltip>
        <Menu
          id="playback-speed-menu"
          anchorEl={animationSpeedEl}
          open={!!animationSpeedEl}
          onClose={() => {
            resume();
            setAnimationSpeedEl(null);
          }}
          MenuListProps={{ 'aria-labelledby': 'playback-speed-button' }}
        >
          {[1, 5, 10, 50, 100, 500, 1000, 3000].map((value) => (
            <MenuItem
              key={value}
              selected={value === playerState.animationSpeed}
              onClick={() => {
                analytics.event('player_animation_speed', {
                  speed: value,
                });

                setPlayerState((prev) => ({
                  ...prev,
                  suspended: false,
                  animationSpeed: value,
                }));
                setAnimationSpeedEl(null);
              }}
            >
              x{value}
            </MenuItem>
          ))}
        </Menu>

        <Tooltip placement="top" title="Trail duration">
          <IconButton
            id="trail-duration-button"
            disabled={disabled}
            aria-controls="trail-duration-menu"
            aria-haspopup="true"
            size="small"
            onClick={(ev) => {
              suspend();
              setTrailLengthEl(ev.currentTarget);
            }}
          >
            <TrailLengthIcon />
          </IconButton>
        </Tooltip>

        <Menu
          id="trail-duration-menu"
          anchorEl={trailLengthEl}
          open={!!trailLengthEl}
          onClose={() => {
            resume();
            setTrailLengthEl(null);
          }}
          MenuListProps={{ 'aria-labelledby': 'trail-duration-button' }}
        >
          {[
            60,
            60 * 5,
            60 * 15,
            60 * 60,
            60 * 60 * 2,
            60 * 60 * 4,
            60 * 60 * 6,
            60 * 60 * 12,
            null,
          ].map((value) => (
            <MenuItem
              key={value ?? 'UNL'}
              selected={
                (value ?? metadata?.duration ?? 3600 * 24) ===
                playerState.displaySettings.trailLength
              }
              onClick={() => {
                analytics.event('player_trail_length', {
                  trail_length: value,
                });

                setPlayerState((prev) => ({
                  ...prev,
                  displaySettings: {
                    ...prev.displaySettings,
                    trailLength: value ?? metadata?.duration ?? 3600 * 24,
                  },
                }));

                resume();
                setTrailLengthEl(null);
              }}
            >
              {value === null ? 'Unlimited' : formatDuration(value)}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  },
);

function formatDuration(durationSeconds: number): string {
  if (durationSeconds < 60 * 60) {
    const minutes = Math.floor(durationSeconds / 60);
    return `${minutes}m`;
  } else {
    const hours = Math.floor(durationSeconds / (60 * 60));
    return `${hours}h`;
  }
}
