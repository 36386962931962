import React, { lazy, Suspense } from 'react';

const ShareLanding = lazy(() => import('./ShareLanding'));

export const ShareLandingLazy: React.FC<
  React.ComponentProps<typeof ShareLanding>
> = function ShareLandingLazy(props) {
  return (
    <Suspense fallback={<div>Loading ...</div>}>
      <ShareLanding {...props} />
    </Suspense>
  );
};

export default ShareLandingLazy;
