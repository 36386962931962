import { Facebook, LinkedIn, Reddit, Twitter } from '@mui/icons-material';
import { IconButton, Popover, Stack } from '@mui/material';
import { bindPopover, PopupState } from 'material-ui-popup-state/core';
import { forwardRef, useMemo } from 'react';
import { useAnalytics } from '../analytics';
import { createUrl, openWindow } from './utils';

const SOCIAL = {
  twitter: '#00ACEE',
  facebook: '#3b5998',
  linkedin: '#007fb1',
  reddit: '#ff4500',
};

const hashtags = ['avgeeks', 'avgeek', 'aviation', 'aboveourheads'];

const SHARE_URL = import.meta.env.BASE_URL ?? 'https://aboveourheads.org/';

export const ShareMenu = forwardRef<
  React.ComponentRef<typeof Popover>,
  { state: PopupState }
>(function ShareMenu({ state }, ref) {
  const [analytics] = useAnalytics();
  // const queryParam = useShareMapState();

  const shareUrl = useMemo(() => {
    const url = SHARE_URL;

    // new URL('/s', SHARE_URL);
    // url.hash = queryParam;
    return url.toString();
  }, []);

  return (
    <Popover
      ref={ref}
      {...bindPopover(state)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Stack sx={{ m: 1 }} spacing={1} direction="row">
        <IconButton
          sx={{ color: SOCIAL.twitter }}
          onClick={() => {
            state.close();
            analytics.event('share', {
              method: 'Twitter',
              content_type: 'website',
            });
            openWindow(
              createUrl('twitter', {
                text: '',
                url: shareUrl,
                hashtags,
              }),
            );
          }}
          size="small"
        >
          <Twitter />
        </IconButton>
        <IconButton
          sx={{ color: SOCIAL.facebook }}
          onClick={() => {
            state.close();
            analytics.event('share', {
              method: 'Facebook',
              content_type: 'website',
            });
            openWindow(
              createUrl('facebook', {
                text: '',
                url: shareUrl,
                hashtags,
              }),
            );
          }}
          size="small"
        >
          <Facebook />
        </IconButton>
        <IconButton
          sx={{ color: SOCIAL.reddit }}
          onClick={() => {
            state.close();
            analytics.event('share', {
              method: 'Reddit',
              content_type: 'website',
            });
            openWindow(
              createUrl('reddit', {
                text: '',
                url: shareUrl,
                hashtags,
              }),
            );
          }}
          size="small"
        >
          <Reddit />
        </IconButton>
        <IconButton
          sx={{ color: SOCIAL.linkedin }}
          onClick={() => {
            state.close();
            analytics.event('share', {
              method: 'LinkedIn',
              content_type: 'website',
            });
            openWindow(
              createUrl('linkedin', {
                text: '',
                url: shareUrl,
                hashtags,
              }),
            );
          }}
          size="small"
        >
          <LinkedIn />
        </IconButton>
      </Stack>
    </Popover>
  );
});
