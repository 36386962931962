import {
  DialogTitle,
  IconButton,
  DialogContent,
  CardActionArea,
  Card,
  CardContent,
  Box,
  Stack,
  Typography,
  Link,
  Button,
  DialogActions,
} from '@mui/material';

import { FilterChip } from '../Player';

import { PRESETS } from './presets';

import { Close as CloseIcon } from '@mui/icons-material';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { usePlayback } from '../Player';
import { useColorFilters } from './useColorFilters';
import { useAnalytics } from '../analytics';

export const PresetSelectionPage: React.FC<{}> =
  function PresetSelectionPage() {
    const [, { resume }] = usePlayback();
    const [, { setState: setColorFilters }] = useColorFilters();
    const [analytics] = useAnalytics();
    const navigate = useNavigate();

    return (
      <>
        <DialogTitle>
          Color presets
          <IconButton
            sx={{ position: 'absolute', right: 8, top: 8 }}
            component={RouterLink}
            to="/"
            onClick={() => {
              resume();
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Stack spacing={1}>
            {PRESETS.map((preset, idx) => (
              <Card
                key={idx}
                sx={{
                  borderColor: 'primary.light',
                  borderWidth: '1px',
                  backgroundColor: 'transparent',
                }}
              >
                <CardActionArea
                  onClick={() => {
                    navigate('/');
                    resume();
                    analytics.event('select_content', {
                      content_type: 'color-preset',
                      item_id: preset.title,
                    });
                    setColorFilters((prev) => ({
                      ...prev,
                      filters: preset.filters,
                    }));
                  }}
                >
                  <CardContent sx={{ color: 'text.secondary' }}>
                    <Typography color="text.primary" variant="h6">
                      {preset.title}
                    </Typography>
                    {preset.description}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        mt: 1,
                        mr: -1,
                        mb: 0,
                        flexWrap: 'wrap',
                      }}
                    >
                      {preset.filters.map((f, idx) => {
                        return (
                          <FilterChip
                            filter={f}
                            sx={{
                              mr: 1,
                              mb: 1,
                            }}
                            size="small"
                            key={f.id}
                          />
                        );
                      })}
                    </Box>
                    {preset.source && (
                      <Link
                        href={preset.source.href}
                        target="_blank"
                        rel="noopener"
                        color="primary.light"
                      >
                        Source: {preset.source.name}
                      </Link>
                    )}
                  </CardContent>
                </CardActionArea>
              </Card>
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            component={RouterLink}
            to="/"
            onClick={() => {
              resume();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </>
    );
  };
