import { useRafLoop } from 'react-use';
import { useEffect, useRef } from 'react';
import { useSetTimeOffset, usePlayback, useTimeOffset } from './hooks';
import { useDatasetInformation } from '../Loader';

export const PlaybackMainLoop: React.FC<{}> = function PlaybackMainLoop() {
  const lastLoopTsRef = useRef<null | number>(null);
  const setTimeOffset = useSetTimeOffset();
  const timeOffset = useTimeOffset();

  const { metadata: datasetMetadata } = useDatasetInformation();
  const loopLength = datasetMetadata?.duration ?? 3600 * 24;

  const [playerState] = usePlayback();
  /**
   * This is the main animation loop
   */
  const [pause, play, isRafLoopActive] = useRafLoop((time) => {
    if (lastLoopTsRef.current === null) {
      lastLoopTsRef.current = time;
      return;
    }

    const msSinceLastRender = time - lastLoopTsRef.current;
    lastLoopTsRef.current = time;

    setTimeOffset((t) => {
      const nextOffset =
        (timeOffset + (msSinceLastRender / 1000) * playerState.animationSpeed) %
        loopLength;

      if (playerState.suspended) {
        return t;
      }

      return nextOffset;
    });
  }, false);

  useEffect(() => {
    const animationRunning = isRafLoopActive();

    if (playerState.playing && !playerState.suspended) {
      if (!animationRunning) {
        console.log('Starting playback');
        lastLoopTsRef.current = null;
        play();
        return;
      }
    }

    if (!playerState.playing || playerState.suspended) {
      if (animationRunning) {
        console.log('Pausing playback');
        lastLoopTsRef.current = null;
        pause();
        return;
      }
    }
  }, [
    lastLoopTsRef,
    play,
    pause,
    isRafLoopActive,
    playerState.playing,
    playerState.suspended,
  ]);

  return null;
};
