import { useTheme, useMediaQuery } from '@mui/material';
import { useDeviceSelectors } from 'react-device-detect';

/**
 * Creates a typescript tuple from arguments
 * e.g: const a: [number, string] = tuple(1, 'abc');
 */
export function tuple<T extends any[]>(...args: T): T {
  return args;
}

export function useIsMobile(): { ua: boolean; mediaQuery: boolean } {
  const [device] = useDeviceSelectors(window.navigator.userAgent);
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.down('md'));

  return { ua: device.isMobile, mediaQuery };
}

export function ftToM(ft: number) {
  return ft * 0.3048;
}

export function mToFt(m: number) {
  return m * 3.28084;
}

export function flToM(fl: number) {
  return ftToM(100 * fl);
}

export function isNotNull<T>(arg: T): arg is Exclude<T, null> {
  return arg !== null;
}
