import { tuple } from '../utils';
import rawDatasets from '../datasets.json';

export const AVAILABLE_DATASETS = rawDatasets.map(({ start, ...rest }) => ({
  ...rest,
  start: new Date(start),
}));

export function useAvailableDatasets() {
  return tuple(AVAILABLE_DATASETS);
}
