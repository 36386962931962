import { atom, useRecoilState } from 'recoil';
import { useCallback } from 'react';
import { tuple } from '../utils';
import type { ColorFilterState } from './types';

import { createCatchAllFilter } from './utils';

const colorFiltersAtom = atom<{
  filters: Array<ColorFilterState>;
}>({
  key: 'baseMap:colorFilters',
  default: {
    filters: [createCatchAllFilter('normal')],
  },
});

export function useColorFilters() {
  const [state, setState] = useRecoilState(colorFiltersAtom);

  const toggleFilter = useCallback(
    (idx: number, enabled?: boolean) => {
      setState((prev) => {
        const oldFilters = prev.filters;
        if (!oldFilters[idx]) {
          return prev;
        }

        // If all filters are enabled and we click one, just select that one
        const nextState = enabled ?? !oldFilters[idx].enabled;
        if (!nextState && oldFilters.every((f) => f.enabled)) {
          const filters = oldFilters.map((f, currentIndex) => {
            if (currentIndex === idx) {
              return f;
            }

            return { ...f, enabled: false };
          });

          return {
            ...prev,
            filters,
          };
        }

        // We're clicking on the last active filter, make all filters active
        if (
          !nextState &&
          oldFilters.every(
            (f, currentIndex) => currentIndex === idx || !f.enabled,
          )
        ) {
          return {
            ...prev,
            filters: oldFilters.map((f) => ({ ...f, enabled: true })),
          };
        }

        const newFilter = {
          ...oldFilters[idx],
          enabled: !oldFilters[idx].enabled,
        };

        const filters = oldFilters.slice();
        filters[idx] = newFilter;

        return {
          ...prev,
          filters,
        };
      });
    },
    [setState],
  );

  const invertFilters = useCallback(() => {
    setState((prev) => {
      if (prev.filters.length === 1) {
        return prev;
      }

      const filters = prev.filters.map((f) => ({ ...f, enabled: !f.enabled }));
      return { ...prev, filters };
    });
  }, [setState]);

  const resetFilterStates = useCallback(() => {
    setState((prev) => {
      const filters = prev.filters.map((f) => ({ ...f, enabled: true }));
      return { ...prev, filters };
    });
  }, [setState]);

  const deleteFilter = useCallback(
    (idx: number) => {
      setState((prev) => {
        if (!prev.filters[idx] || idx === prev.filters.length - 1) {
          return prev;
        }

        const filters = prev.filters.slice();
        filters.splice(idx, 1);

        return {
          ...prev,
          filters,
        };
      });
    },
    [setState],
  );

  const clearFilters = useCallback(() => {
    setState((prev) => ({
      ...prev,
      filters: [createCatchAllFilter('normal')],
    }));
  }, [setState]);

  return tuple(state, {
    setState,
    toggleFilter,
    deleteFilter,
    invertFilters,
    resetFilterStates,
    clearFilters,
  });
}
