import { useEffect, useCallback, useMemo } from 'react';
import { useCookieConsent } from './useCookieConsent';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { tuple } from '../utils';

const GA_ID = import.meta.env.VITE_GA_ID as string;

export const AnalyticsProvider: React.FC<{}> = function AnalyticsProvider({
  children,
}) {
  const [{ consent }] = useCookieConsent();
  const location = useLocation();

  /**
   * Start GA when consent is given
   */
  useEffect(() => {
    if (consent === true && GA_ID) {
      console.log('[AnalyticsProvider]: Starting GA');
      ReactGA.initialize([
        {
          trackingId: GA_ID,
        },
      ]);
    } else {
      console.log('[AnalyticsProvider]: Stopping GA');
    }
  }, [consent]);

  /**
   * Plug GA to react-router
   */
  useEffect(() => {
    if (!consent) {
      return;
    }

    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
    });
  }, [consent, location.pathname]);

  return <>{children}</>;
};

interface GAEvent {
  (
    name: 'select_content',
    params: { content_type: string; item_id?: string },
  ): void;
  (
    name: 'share',
    params: { method: string; content_type?: string; item_id?: string },
  ): void;
  (
    name:
      | 'player_play'
      | 'player_pause'
      | 'player_skip'
      | 'player_skip_forward'
      | 'player_skip_back',
    params?: { time_offset?: number; animation_speed?: number },
  ): void;
  (name: 'player_trail_length', params: { trail_length: number | null }): void;
  (name: 'player_animation_speed', params: { speed: number }): void;
  (
    name: 'share',
    params: { method: string; content_type?: string; item_id?: string },
  ): void;
}

export function useAnalytics() {
  const [{ consent }] = useCookieConsent();

  const event = useCallback<GAEvent>(
    (name: string, params?: object) => {
      if (!consent) {
        return;
      }

      ReactGA.event(name, params);
    },
    [consent],
  );

  const r = useMemo(() => {
    return tuple({ event });
  }, [event]);

  return r;
}
