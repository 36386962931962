import { useCallback } from 'react';
import { atom, useRecoilValue } from 'recoil';
import { tuple } from '../utils';
import type { MainToWorkerMessage, WorkerToMainMessage } from '../worker';
import AOHWorker from '../worker/index.ts?worker';

const worker = new AOHWorker();

const workerAtom = atom<Worker>({
  key: 'worker',
  default: worker,
});

export function useWorker() {
  const worker = useRecoilValue(workerAtom);

  const postMessage = useCallback(
    (message: MainToWorkerMessage) => {
      worker.postMessage(message);
    },
    [worker],
  );

  const onMessage = useCallback(
    <T extends WorkerToMainMessage['type']>(
      messageType: T,
      listener: (
        event: MessageEvent<Extract<WorkerToMainMessage, { type: T }>>,
      ) => any,
    ) => {
      function wrappedListener(event: MessageEvent<WorkerToMainMessage>) {
        if (!event.data?.type) {
          return;
        }

        if (event.data.type !== messageType) {
          return;
        }

        console.debug(`[main] Received message ${event.data.type}`, event.data);
        listener(event as any);
      }

      worker.addEventListener('message', wrappedListener);

      return () => {
        worker.removeEventListener('message', wrappedListener);
      };
    },
    [worker],
  );

  return tuple(worker, { postMessage, onMessage });
}
