import { ArrowForward, Check, ErrorOutline } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Fab,
  Fade,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDatasetLoader } from './hooks';
import { Tip } from './Tip';

export const LoaderInformation: React.FC<{}> = function LoaderInformation() {
  const [
    { metadata, statistics, loading, error, chunks, loadOptions },
    { start: startDatasetLoading, selectDataset },
  ] = useDatasetLoader();

  const theme = useTheme();

  const [keptOpen, setKeptOpen] = useState(true);

  useEffect(() => {
    if (!loading && !error) {
      const timeoutId = setTimeout(() => {
        setKeptOpen(false);
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [loading, error, setKeptOpen]);

  useEffect(() => {
    const abort = startDatasetLoading();
    setKeptOpen(true);
    return abort;
  }, [startDatasetLoading]);

  const dialogState: 'closed' | 'closing' | 'error' | 'loading' | 'no-dataset' =
    (() => {
      if (loading) {
        return 'loading';
      }

      if (error) {
        return 'error';
      }

      if (!metadata) {
        return 'no-dataset';
      }

      if (keptOpen) {
        return 'closing';
      }

      return 'closed';
    })();

  const titles = {
    loading: 'Loading dataset',
    closing: 'Done !',
    closed: 'Done !',
    'no-dataset': 'Please select a dataset',
    error: 'An error happened',
  };

  const visible = dialogState !== 'closed';

  const fabProps =
    dialogState === 'no-dataset'
      ? {
          component: RouterLink,
          to: 'settings',
        }
      : {};

  return (
    <Fade in={visible} unmountOnExit timeout={{ exit: 750 }}>
      <Card>
        <CardHeader title={titles[dialogState]} />
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minWidth: 300,
          }}
        >
          <Box sx={{ m: 1, mb: 2, position: 'relative' }}>
            <Fab
              color={
                dialogState === 'no-dataset' || dialogState === 'loading'
                  ? 'primary'
                  : undefined
              }
              sx={{
                bgcolor:
                  dialogState === 'error'
                    ? 'error.main'
                    : dialogState === 'closing' || dialogState === 'closed'
                    ? 'success.main'
                    : undefined,
              }}
              {...fabProps}
            >
              {dialogState === 'error' ? (
                <ErrorOutline />
              ) : dialogState === 'closing' || dialogState === 'closed' ? (
                <Check />
              ) : dialogState === 'loading' ? (
                <>
                  {Math.round(
                    100 *
                      (statistics.downloadedFlights /
                        (loadOptions?.hardLimit ??
                          metadata?.trajectoryCount ??
                          100)),
                  )}
                  %
                </>
              ) : (
                <ArrowForward />
              )}
            </Fab>
            {(dialogState === 'loading' ||
              dialogState === 'closing' ||
              dialogState === 'closed' ||
              dialogState === 'error') && (
              <CircularProgress
                sx={{
                  position: 'absolute',
                  top: -6,
                  left: -6,
                  zIndex: 1,
                  // Disable transition as we update the component quite frequently
                  '& .MuiCircularProgress-circle': {
                    transition: theme.transitions.create('color'),
                  },
                }}
                color={
                  {
                    loading: 'primary' as const,
                    closing: 'success' as const,
                    closed: 'success' as const,
                    error: 'error' as const,
                    'no-dataset': 'primary' as const,
                  }[dialogState]
                }
                variant="determinate"
                value={
                  {
                    closed: 100,
                    closing: 100,
                    error: 100,
                    'no-dataset': 0,
                    loading:
                      100 *
                      (statistics.downloadedFlights /
                        (loadOptions?.hardLimit ??
                          metadata?.trajectoryCount ??
                          100)),
                  }[dialogState]
                }
                size={68}
              />
            )}
          </Box>
          {dialogState === 'loading' && (
            <>
              <Typography color="text.disabled" variant="caption">
                Loading batch #{chunks.length + 1}. Flights loaded:{' '}
                {statistics.downloadedFlights} /{' '}
                {loadOptions?.hardLimit ?? metadata?.trajectoryCount}.
              </Typography>
              <Button
                sx={{ mt: 1 }}
                variant="outlined"
                color="error"
                size="small"
                onClick={() => {
                  selectDataset(null);
                }}
              >
                Abort
              </Button>
            </>
          )}
          {error && (
            <Typography color="error" variant="caption">
              {error.message}. Try refreshing the page.
            </Typography>
          )}
          <Tip
            sx={{ mt: 2, width: 450, maxWidth: '80vw' }}
            variant="standard"
            severity="info"
          />
        </CardContent>
      </Card>
    </Fade>
  );
};
