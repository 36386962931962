import { useTheme, Slider } from '@mui/material';
import { useAnalytics } from '../analytics';
import { useDatasetInformation } from '../Loader';
import { usePlayback, useTimeOffsetThrottled } from './hooks';
import { useCallback, useEffect, useState } from 'react';
import { timeOffsetToString } from './PlayerTime';

export const PlaybackProgress: React.FC<{ disabled?: boolean }> =
  function PlaybackProgress({ disabled = false }) {
    const theme = useTheme();
    const [dragging, setDragging] = useState(false);
    const [playerState, { suspend, resume, setTimeOffset }] = usePlayback();
    const [analytics] = useAnalytics();
    const { metadata: datasetMetadata } = useDatasetInformation();
    const loopLength = datasetMetadata?.duration ?? 3600 * 24;

    const timeOffset = useTimeOffsetThrottled(33);

    const [sliderLocalValue, setSliderLocalValue] =
      useState<number>(timeOffset);

    useEffect(() => {
      if (dragging) {
        return;
      }

      setSliderLocalValue(timeOffset);
    }, [setSliderLocalValue, timeOffset, dragging]);

    const onChange = useCallback(
      (_, newValue: number | number[]) => {
        setDragging(true);
        suspend();
        setSliderLocalValue(newValue as number);
      },
      [suspend, setSliderLocalValue, setDragging],
    );

    const onChangeCommited = useCallback(
      (ev, newValue: number | number[]) => {
        setDragging(false);
        analytics.event('player_skip', {
          animation_speed: playerState.animationSpeed,
        });
        setSliderLocalValue(newValue as number);
        setTimeOffset(newValue as number);
        resume();
      },
      [
        setDragging,
        analytics,
        playerState.animationSpeed,
        setSliderLocalValue,
        setTimeOffset,
        resume,
      ],
    );

    const valueLabelFormat = useCallback(
      (value) => timeOffsetToString(value, { showSeconds: true }),
      [],
    );

    return (
      <Slider
        sx={{
          maxWidth: `calc(100% - ${theme.spacing(4)})`,
          alignSelf: 'center',
          height: 4,
          '& .MuiSlider-thumb': {
            width: 8,
            height: 8,
            transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
            '&:before': {
              boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
            },
            '&:hover, &.Mui-focusVisible': {
              boxShadow: `0px 0px 0px 8px ${
                theme.palette.mode === 'dark'
                  ? 'rgb(255 255 255 / 16%)'
                  : 'rgb(0 0 0 / 16%)'
              }`,
            },
            '&.Mui-active': {
              width: 20,
              height: 20,
            },
          },
          '& .MuiSlider-rail': {
            opacity: 0.28,
          },
        }}
        valueLabelFormat={valueLabelFormat}
        disabled={disabled}
        min={0}
        valueLabelDisplay="auto"
        max={loopLength - 1}
        value={sliderLocalValue}
        onChange={onChange}
        onChangeCommitted={onChangeCommited}
        size="small"
      />
    );
  };
