import { Share as ShareIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import type React from 'react';
import { ShareMenu } from '../Share';
import { Link as RouterLink } from 'react-router-dom';

export const ShareButton: React.FC<{ disabled?: boolean }> =
  function ShareButton({ disabled = false }) {
    const shareMenu = usePopupState({
      variant: 'popover',
      popupId: 'shareMenu',
    });

    return (
      <>
        <Tooltip placement="top" title="Share this website">
          <IconButton component={RouterLink} to="/share" disabled={disabled}>
            <ShareIcon />
          </IconButton>
        </Tooltip>

        <ShareMenu state={shareMenu} />
      </>
    );
  };
