import { createTheme } from '@mui/material';
import { useMemo } from 'react';
import type { ColorFilterState } from './types';

export function useColorFilterMuiTheme({
  filter,
}: {
  filter: ColorFilterState;
}) {
  const theme = useMemo(() => {
    let color = filter.color;
    if (filter.type === 'altitudeGradient') {
      color = filter.bottomColor;
    }

    if (filter.type === 'verticalSpeed') {
      color = filter.descentColor;
    }

    /**
     * If have some transparent filter, use some neutral shade of grey
     */
    if (
      filter.color[0] === 0 &&
      filter.color[1] === 0 &&
      filter.color[2] === 0 &&
      filter.color[3] === 0
    ) {
      color = [0xaa, 0xaa, 0xaa, 0xff];
    }

    const filterColorString = `rgb(${color[0]}, ${color[1]}, ${color[2]})`;

    return createTheme({
      palette: {
        primary: {
          main: filterColorString,
        },
        mode: 'dark',
      },
    });
  }, [filter]);

  const gradient = useMemo(() => {
    if (!filter.enabled) {
      return;
    }
    return getFilterGradient(filter);
  }, [filter]);

  const solidColor = useMemo(() => toRGBString(filter.color), [filter.color]);

  return { theme, gradient, solidColor };
}

function toRGBString([r, g, b, a]: [number, number, number, number?]): string {
  return `rgb(${r}, ${g}, ${b})`;
}

function toRGBAString(
  [r, g, b, a]: [number, number, number, number],
  {
    minOpacity = 0,
    alphaOffset = 0,
  }: { minOpacity?: number; alphaOffset?: number } = {},
): string {
  const opacity = Math.max(Math.min(1, (a + alphaOffset) / 255), minOpacity);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export function getFilterGradient(
  filter: ColorFilterState,
): undefined | string {
  if (filter.type === 'altitudeGradient') {
    return [
      `linear-gradient(to top right, ${toRGBString(
        filter.bottomColor,
      )}, ${toRGBString(filter.bottomColor)} 45%, ${toRGBString(
        filter.topColor,
      )} 55%, ${toRGBString(filter.topColor)})`,
    ].join(', ');
  }

  if (filter.type === 'verticalSpeed') {
    return [
      `linear-gradient(to top right, ${toRGBString(
        filter.descentColor,
      )}, ${toRGBString(filter.descentColor)} 45%, ${toRGBString(
        filter.climbColor,
      )} 55%, ${toRGBString(filter.climbColor)})`,
    ].join(', ');
  }
}
