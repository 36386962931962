import { atom, useRecoilValue, useRecoilState } from 'recoil';
import { useMemo, useCallback } from 'react';
import { tuple } from '../utils';
import { useWorker } from './useWorker';
import { useResolvedPath } from 'react-router-dom';
import { useAnalytics } from '../analytics';
import type { MultiPolygon, Polygon } from '@turf/helpers';
import { useGlobalViewState } from '../Map';
import centroid from '@turf/centroid';

export function useDatasetInformation() {
  const [{ loading, error, statistics, metadata }] = useDatasetLoader();

  const r = useMemo(
    () => ({
      loading: loading,
      loaded: loading === false && metadata !== null,
      error: error,
      statistics,
      metadata,
    }),
    [loading, error, metadata, statistics],
  );

  return r;
}

export function useIsDatasetSelected(): boolean {
  const dataloadSettings = useRecoilValue(selectedDatasetAtom);
  return !!dataloadSettings?.dataset;
}

export type DatasetMetadata = {
  id: string;
  name: string;
  url: string | Array<string>;
  coverageGeoJSON: null | Polygon;
  includesDepDest: boolean;
  start: Date;
  duration: number;
  trajectoryCount: number;
};

export type DatasetLoadOptions = {
  sampleRate: number;
  hardLimit: number | null;
  batchSize: number;
  resetCamera: boolean;
};

const selectedDatasetAtom = atom<null | {
  dataset: DatasetMetadata;
  loadOptions: DatasetLoadOptions;
}>({
  key: 'loader:selectedDataset',
  default: null,
});

export const datasetAtom = atom<{
  loading: boolean;
  error: null | Error;
  trajectoryChunks: Array<{
    flightInfo: Array<{ callsign: string; icaoId?: string; ao?: string }>;
    positions: Float32Array;
    timestampOffsets: Float32Array;
    startIndices: Uint32Array;
  }>;
}>({
  key: 'loader:dataset',
  default: {
    error: null,
    loading: false,
    trajectoryChunks: [],
  },
});

export const dataStatisticsAtom = atom<{
  flights: number;
  drawnDataPoints: number;
  totalDataPoints: number;
  downloadedFlights: number;
  countByAo: { [key: string]: number };
}>({
  key: 'baseMap:dataStatistics',
  default: {
    flights: 0,
    drawnDataPoints: 0,
    totalDataPoints: 0,
    downloadedFlights: 0,
    countByAo: {},
  },
});

export function useDatasetLoader() {
  const [selectedDataset, setSelectedDataset] =
    useRecoilState(selectedDatasetAtom);

  const [data, setData] = useRecoilState(datasetAtom);
  const [statistics, setStatistics] = useRecoilState(dataStatisticsAtom);

  const [, { postMessage, onMessage }] = useWorker();

  const [analytics] = useAnalytics();

  const rootPath = useResolvedPath('/');

  const [, { setInitialViewState, flyTo }] = useGlobalViewState();

  const start = useCallback(() => {
    if (!selectedDataset) {
      return;
    }

    if (
      selectedDataset.dataset.coverageGeoJSON &&
      selectedDataset.loadOptions.resetCamera
    ) {
      console.log('Setting initial camera view !');
      const center = centroid(selectedDataset.dataset.coverageGeoJSON);
      const centerCoords = center.geometry.coordinates;

      setInitialViewState((prev) => ({
        ...prev,
        longitude: centerCoords[0],
        latitude: centerCoords[1],
      }));

      flyTo((prev) => ({
        ...prev,
        longitude: centerCoords[0],
        latitude: centerCoords[1],
      }));
    }

    let running = true;

    analytics.event('select_content', {
      content_type: 'dataset',
      item_id: selectedDataset.dataset.id,
    });

    const chunkListener = onMessage('LOAD_CHUNK', (event) => {
      setData((prev) => {
        const trajectoryChunks = prev.trajectoryChunks.slice();
        trajectoryChunks[event.data.idx] = event.data.chunk;

        return {
          ...prev,
          trajectoryChunks,
        };
      });
    });

    const progressListener = onMessage('LOAD_PROGRESS', (event) => {
      const statistics = event.data.statistics;
      setStatistics((prev) => ({ ...prev, ...statistics }));
    });

    const errorListener = onMessage('LOAD_ERROR', (event) => {
      const message = event.data.errorMessage;
      running = false;

      setData((prev) => ({
        ...prev,
        loading: false,
        error: new Error(message),
        trajectoryChunks: [],
      }));
      removeListeners();
    });

    const endListener = onMessage('LOAD_DONE', (event) => {
      running = false;
      setData((prev) => ({ ...prev, loading: false }));
      removeListeners();
    });

    function removeListeners() {
      chunkListener();
      progressListener();
      errorListener();
      endListener();
    }

    setData((prev) => ({
      ...prev,
      loading: true,
      error: null,
      trajectoryChunks: [],
    }));

    setStatistics((prev) => ({
      ...prev,
      countByAo: {},
      downloadedFlights: 0,
      flights: 0,
      drawnDataPoints: 0,
      totalDataPoints: 0,
    }));

    postMessage({
      type: 'LOAD_START',
      metadata: {
        ...selectedDataset.dataset,
        url: (typeof selectedDataset.dataset.url === 'string'
          ? [selectedDataset.dataset.url]
          : selectedDataset.dataset.url
        ).map((u) => `${rootPath.pathname}${u}`),
      },
      loadOptions: selectedDataset.loadOptions,
    });

    return function cancel() {
      if (running === false) {
        return;
      }

      removeListeners();

      setData((prev) => ({
        ...prev,
        loading: false,
        trajectoryChunks: [],
        error: null,
      }));

      setStatistics((prev) => ({
        ...prev,
        countByAo: {},
        downloadedFlights: 0,
        flights: 0,
        drawnDataPoints: 0,
        totalDataPoints: 0,
      }));

      setSelectedDataset(null);
      postMessage({ type: 'LOAD_ABORT' });
    };
  }, [
    setData,
    setStatistics,
    setSelectedDataset,
    selectedDataset,
    postMessage,
    onMessage,
    rootPath.pathname,
    analytics,
    setInitialViewState,
    flyTo,
  ]);

  const selectDataset = useCallback(
    (
      opts: null | {
        dataset: DatasetMetadata;
        loadOptions: DatasetLoadOptions;
      },
    ) => {
      setSelectedDataset(opts);
    },
    [setSelectedDataset],
  );

  return tuple(
    {
      loading: data.loading,
      error: data.error,
      chunks: data.trajectoryChunks,
      statistics,
      metadata: selectedDataset?.dataset ?? null,
      loadOptions: selectedDataset?.loadOptions ?? null,
    },
    { start, selectDataset },
  );
}
