import { useEffect } from 'react';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { useWorker } from '../Loader/useWorker';
import { useColorFilters } from './useColorFilters';

const colorBufferAtom = atom<{
  loading: boolean;
  error: null | Error;
  colors: Array<Uint8Array>;
}>({
  key: 'color:buffers',
  default: {
    loading: false,
    error: null,
    colors: [],
  },
});

export const ColorBufferProvider: React.FC<{}> =
  function ColorBufferProvider() {
    const [, setState] = useRecoilState(colorBufferAtom);
    const [{ filters }] = useColorFilters();
    const [, { onMessage, postMessage }] = useWorker();

    useEffect(() => {
      const onDatasetLoadStart = onMessage('LOAD_START', () => {
        console.log(
          '[useColorBuffers]: New dataset load, clearing color buffers',
        );
        setState({ loading: true, error: null, colors: [] });
      });

      const onStartListener = onMessage('COLOR_START', () => {
        console.log('[useColorBuffers]: Computing started');
        setState((prev) => ({ ...prev, loading: true, error: null }));
      });

      const onErrorListener = onMessage('COLOR_ERROR', (event) => {
        const { errorMessage } = event.data;
        console.log('[useColorBuffers]: Computing errored');

        setState((prev) => ({
          ...prev,
          colors: [],
          error: new Error(errorMessage),
        }));
      });

      const onChunkListener = onMessage('COLOR_CHUNK', (event) => {
        const { chunkIndices, colors } = event.data;

        console.log(
          `[useColorBuffers]: Received color for chunks ${chunkIndices.join(
            ', ',
          )}`,
        );

        setState((prev) => {
          const nextColors = prev.colors.slice();
          for (const [messageIndex, chunkIndex] of chunkIndices.entries()) {
            const c = colors[messageIndex];
            if (!c) {
              continue;
            }

            nextColors[chunkIndex] = c;
          }

          return {
            ...prev,
            colors: nextColors,
          };
        });
      });

      const onDoneListener = onMessage('COLOR_DONE', () => {
        console.log('[useColorBuffers]: Computing done !');
        setState((prev) => ({ ...prev, loading: false }));
      });

      function removeListeners() {
        onStartListener();
        onErrorListener();
        onChunkListener();
        onDoneListener();
        onDatasetLoadStart();
      }

      return () => {
        removeListeners();
      };
    }, [onMessage, setState]);

    useEffect(() => {
      postMessage({ type: 'SET_COLOR_RULES', rules: filters });
    }, [postMessage, filters]);

    return null;
  };

export function useColorBuffers() {
  const state = useRecoilValue(colorBufferAtom);

  return state;
}
