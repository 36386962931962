import {
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardProps,
  Collapse,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Popover,
  Slider,
  Switch,
  TextField,
  ThemeProvider,
  Typography,
  useTheme,
} from '@mui/material';
import { schemeTableau10 } from 'd3-scale-chromatic';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import { forwardRef, useState } from 'react';
import { CirclePicker } from 'react-color';
import type { ListActions } from 'react-use/lib/useList';
import type { ColorFilterState } from '.';
import { useRankedAircraftOperators } from '../Loader/useRankedAircraftOperators';
import { useIsMobile } from '../utils';
import { ColorFilterIcon } from './ColorFilterIcon';
import type {
  IAltitudeGradientFilter,
  IColorFilterState,
  RGBAColor,
  RGBColor,
} from './types';
import { useColorFilterMuiTheme } from './useColorFilterMuiTheme';

type FilterType = ColorFilterState['type'];

export const ColorFilterEditionCard = forwardRef<
  React.ComponentRef<typeof Card>,
  {
    filter: ColorFilterState;
    deletable: boolean;
    toggable: boolean;
    idx: number;
    actions: ListActions<ColorFilterState>;
    initiallyExpanded?: boolean;
  } & CardProps
>(function ColorFilterEditionCard(
  { filter, idx, actions, initiallyExpanded, deletable, toggable, sx, ...rest },
  ref,
) {
  const [expanded, setExpanded] = useState(!!initiallyExpanded);
  const theme = useTheme();
  const rankedAos = useRankedAircraftOperators();

  const { mediaQuery: isMobile } = useIsMobile();

  const subheader: Partial<Record<FilterType, string>> = {
    aircraftOperator: 'Aircraft operator',
    callsign: 'Callsign',
    catchAll: 'Anything',
    altitudeGradient: 'Altitude',
    airport: 'Airport',
  };

  const {
    theme: filterTheme,
    gradient: colorGradient,
    solidColor: filterColorString,
  } = useColorFilterMuiTheme({ filter });

  const colorPickerMenu = usePopupState({
    variant: 'popover',
    popupId: `colorPicker-${filter.id}`,
  });

  return (
    <>
      <Card ref={ref} {...rest} variant="outlined" sx={sx}>
        <CardHeader
          title={filter.displayName}
          subheader={subheader[filter.type]}
          avatar={
            <ThemeProvider theme={filterTheme}>
              <IconButton
                color="primary"
                size={isMobile ? 'small' : 'large'}
                {...bindTrigger(colorPickerMenu)}
              >
                <Avatar
                  sx={
                    filter.enabled
                      ? {
                          background: colorGradient ?? filterColorString,
                          color:
                            theme.palette.getContrastText(filterColorString),
                        }
                      : {
                          color: colorGradient ?? filterColorString,
                          background: 'transparent',
                          border: `1px solid ${filterColorString}`,
                        }
                  }
                >
                  <ColorFilterIcon type={filter.type} />
                </Avatar>
              </IconButton>
            </ThemeProvider>
          }
          action={
            <IconButton onClick={() => setExpanded((prev) => !prev)}>
              <ExpandMoreIcon
                sx={{
                  transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
                  transition: theme.transitions.create('transform', {
                    duration: theme.transitions.duration.shortest,
                  }),
                }}
              />
            </IconButton>
          }
        />

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Divider />

          <CardContent>
            <TextField
              sx={{ mb: 2 }}
              variant="outlined"
              fullWidth
              size="small"
              label="Display name"
              value={filter.displayName}
              onChange={(ev) => {
                const newName = ev.target.value.slice(0, 30);
                actions.updateAt(idx, { ...filter, displayName: newName });
              }}
            />
            {filter.type === 'aircraftOperator' && (
              <Autocomplete
                id="aircraft-operator-filter"
                multiple
                disableClearable
                fullWidth
                autoHighlight
                value={filter.aircraftOperators}
                options={rankedAos as Array<typeof rankedAos[number] | string>}
                getOptionLabel={(item) => {
                  if (typeof item === 'string') {
                    return item;
                  }

                  return item.commonName
                    ? `${item.commonName} (${item.prefix}, ${item.count})`
                    : `${item.prefix} (${item.count})`;
                }}
                isOptionEqualToValue={(option, value) =>
                  (typeof value === 'string' ? value : value.prefix) ===
                  (typeof option === 'string' ? option : option.prefix)
                }
                onChange={(ev, value) => {
                  actions.updateAt(idx, {
                    ...filter,
                    aircraftOperators: value.map((v) =>
                      typeof v === 'string' ? v : v.prefix,
                    ),
                  });
                }}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Aircraft operator prefix"
                  />
                )}
              />
            )}

            {filter.type === 'callsign' && (
              <>
                <TextField
                  sx={{ mb: 2 }}
                  variant="outlined"
                  fullWidth
                  size="small"
                  label="Callsign prefix"
                  value={filter.startsWith}
                  onChange={(ev) => {
                    const newName = ev.target.value.slice(0, 30);
                    actions.updateAt(idx, { ...filter, startsWith: newName });
                  }}
                />
                <TextField
                  id="max-callsign-length"
                  sx={{ mb: 2 }}
                  variant="outlined"
                  fullWidth
                  select
                  size="small"
                  label="Max length"
                  onChange={(ev) => {
                    const value = ev.target.value;
                    const { maxLength, ...rest } = filter;

                    if (!value || parseInt(value, 10) === 0) {
                      actions.updateAt(idx, { ...rest });
                    } else {
                      actions.updateAt(idx, {
                        ...rest,
                        maxLength: parseInt(value, 10),
                      });
                    }
                  }}
                  value={filter.maxLength ?? 0}
                >
                  {[0, 1, 2, 3, 4, 5, 6, 7].map((val, idx) => (
                    <MenuItem key={idx} value={val}>
                      {val ? `${val} char.` : 'None'}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="min-callsign-length"
                  sx={{ mb: 2 }}
                  variant="outlined"
                  fullWidth
                  select
                  size="small"
                  label="Min length"
                  onChange={(ev) => {
                    const value = ev.target.value;
                    const { minLength, ...rest } = filter;

                    if (!value || parseInt(value, 10) === 0) {
                      actions.updateAt(idx, { ...rest });
                    } else {
                      actions.updateAt(idx, {
                        ...rest,
                        minLength: parseInt(value, 10),
                      });
                    }
                  }}
                  value={filter.minLength ?? 0}
                >
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((val, idx) => (
                    <MenuItem key={idx} value={val}>
                      {val ? `${val} char.` : 'None'}
                    </MenuItem>
                  ))}
                </TextField>
              </>
            )}

            {filter.type === 'altitudeGradient' && (
              <>
                <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column' }}>
                  <TextField
                    id="top-altitude"
                    variant="outlined"
                    type="number"
                    inputProps={{
                      min: 0,
                      step: 100,
                    }}
                    fullWidth
                    size="small"
                    required
                    margin="normal"
                    label="Top altitude (ft)"
                    value={`${filter.topFt}`}
                    onChange={(ev) => {
                      const value = ev.target.value;
                      if (value.length === 0) {
                        actions.updateAt(idx, { ...filter, topFt: 0 });
                        return;
                      }

                      const topFt = parseInt(ev.target.value, 10);
                      actions.updateAt(idx, { ...filter, topFt });
                    }}
                  />

                  <TextField
                    id="above-altitude-mode"
                    variant="outlined"
                    select
                    fullWidth
                    size="small"
                    required
                    margin="dense"
                    label="Above top handling"
                    helperText={
                      ALTITUDE_FILTER_OOB_MODES[filter.aboveMode]?.helperText
                    }
                    value={filter.aboveMode}
                    onChange={(ev) => {
                      const value = ev.target.value;

                      if (
                        value !== 'clamp' &&
                        value !== 'passthrough' &&
                        value !== 'exclude'
                      ) {
                        return;
                      }

                      actions.updateAt(idx, { ...filter, aboveMode: value });
                    }}
                  >
                    {Object.entries(ALTITUDE_FILTER_OOB_MODES).map(
                      ([v, { displayName }]) => (
                        <MenuItem key={v} value={v}>
                          {displayName}
                        </MenuItem>
                      ),
                    )}
                  </TextField>
                </Box>

                <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column' }}>
                  <TextField
                    id="bottom-altitude"
                    variant="outlined"
                    type="number"
                    inputProps={{
                      min: 0,
                      step: 100,
                    }}
                    fullWidth
                    size="small"
                    required
                    margin="normal"
                    label="Bottom altitude (ft)"
                    value={`${filter.bottomFt}`}
                    onChange={(ev) => {
                      const value = ev.target.value;
                      if (value.length === 0) {
                        actions.updateAt(idx, { ...filter, bottomFt: 0 });
                        return;
                      }

                      const bottomFt = parseInt(ev.target.value, 10);
                      actions.updateAt(idx, { ...filter, bottomFt });
                    }}
                  />

                  <TextField
                    id="below-altitude-mode"
                    variant="outlined"
                    select
                    fullWidth
                    size="small"
                    required
                    margin="dense"
                    label="Below bottom handling"
                    helperText={
                      ALTITUDE_FILTER_OOB_MODES[filter.belowMode]?.helperText
                    }
                    value={filter.belowMode}
                    onChange={(ev) => {
                      const value = ev.target.value;

                      if (
                        value !== 'clamp' &&
                        value !== 'passthrough' &&
                        value !== 'exclude'
                      ) {
                        return;
                      }

                      actions.updateAt(idx, { ...filter, belowMode: value });
                    }}
                  >
                    {Object.entries(ALTITUDE_FILTER_OOB_MODES).map(
                      ([v, { displayName }]) => (
                        <MenuItem key={v} value={v}>
                          {displayName}
                        </MenuItem>
                      ),
                    )}
                  </TextField>
                </Box>
              </>
            )}
          </CardContent>
        </Collapse>

        {(toggable || deletable) && (
          <>
            <Divider />
            <CardActions>
              {toggable && (
                <Switch
                  color="primary"
                  checked={filter.enabled}
                  onChange={() => {
                    actions.updateAt(idx, {
                      ...filter,
                      enabled: !filter.enabled,
                    });
                  }}
                />
              )}

              <Box sx={{ flexGrow: 1 }} />
              {deletable && (
                <IconButton
                  size="small"
                  onClick={() => {
                    actions.removeAt(idx);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </CardActions>
          </>
        )}
      </Card>

      <Popover
        ref={ref}
        {...bindPopover(colorPickerMenu)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        sx={{ mt: 1 }}
        PaperProps={{ sx: { p: 2 } }}
      >
        {filter.type === 'altitudeGradient' ? (
          <>
            <Box>
              <Typography
                sx={{ mb: 0.5 }}
                variant="caption"
                color="text.secondary"
              >
                Top color
              </Typography>
              <ColorPicker
                value={filter.topColor}
                onChangeComplete={(color) => {
                  actions.updateAt(idx, {
                    ...filter,
                    topColor: color,
                  });
                }}
              />
            </Box>
            <Divider sx={{ mt: 1, mb: 1 }} />
            <Box>
              <Typography
                sx={{ mb: 0.5 }}
                variant="caption"
                color="text.secondary"
              >
                Bottom color
              </Typography>
              <ColorPicker
                value={filter.bottomColor}
                onChangeComplete={(color) => {
                  actions.updateAt(idx, {
                    ...filter,
                    color,
                    bottomColor: color,
                  });
                }}
              />
            </Box>
          </>
        ) : filter.type === 'verticalSpeed' ? (
          <>
            {' '}
            <Box>
              <Typography
                sx={{ mb: 0.5 }}
                variant="caption"
                color="text.secondary"
              >
                Climb color
              </Typography>
              <ColorPicker
                value={filter.climbColor}
                onChangeComplete={(color) => {
                  actions.updateAt(idx, {
                    ...filter,
                    climbColor: color,
                  });
                }}
              />
            </Box>
            <Divider sx={{ mt: 1, mb: 1 }} />
            <Box>
              <Typography
                sx={{ mb: 0.5 }}
                variant="caption"
                color="text.secondary"
              >
                Level color
              </Typography>
              <ColorPicker
                value={filter.color}
                onChangeComplete={(color) => {
                  actions.updateAt(idx, {
                    ...filter,
                    color,
                  });
                }}
              />
            </Box>
            <Divider sx={{ mt: 1, mb: 1 }} />
            <Box>
              <Typography
                sx={{ mb: 0.5 }}
                variant="caption"
                color="text.secondary"
              >
                Descent color
              </Typography>
              <ColorPicker
                value={filter.descentColor}
                onChangeComplete={(color) => {
                  actions.updateAt(idx, {
                    ...filter,
                    descentColor: color,
                  });
                }}
              />
            </Box>
          </>
        ) : (
          <ColorPicker
            value={filter.color}
            onChangeComplete={(color) => {
              actions.updateAt(idx, {
                ...filter,
                color,
              });
            }}
          />
        )}
      </Popover>
    </>
  );
});

const ALTITUDE_FILTER_OOB_MODES: Record<
  IAltitudeGradientFilter['aboveMode'],
  { helperText: string; displayName: string }
> = {
  clamp: {
    helperText:
      'Positions with out of bound altitude will be rendered with the top color',
    displayName: 'Clamp',
  },
  exclude: {
    helperText: 'Positions with out of bound altitude will not be rendered',
    displayName: 'Exclude',
  },
  passthrough: {
    helperText:
      'Positions with out of bound altitude will be passed through to the next color rule',
    displayName: 'Passthrough',
  },
};

const ColorPicker: React.FC<{
  palette?: Array<string | RGBAColor | RGBColor>;
  value: RGBAColor;
  onChangeComplete: (color: RGBAColor) => any;
}> = function ColorPicker({ value, palette, onChangeComplete }) {
  return (
    <>
      <Box sx={{ mb: 2 }}>
        <CirclePicker
          color={{
            r: value[0],
            g: value[1],
            b: value[2],
            a: value[3] / 255,
          }}
          circleSpacing={6}
          circleSize={14}
          onChangeComplete={(color) => {
            onChangeComplete([color.rgb.r, color.rgb.g, color.rgb.b, value[3]]);
          }}
          colors={schemeTableau10 as Array<string>}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <VisibilityIcon fontSize="small" sx={{ mr: 2 }} />
        <Slider
          valueLabelDisplay="auto"
          min={0}
          max={255}
          value={value[3]}
          step={1 / 255}
          size="small"
          valueLabelFormat={(x) => `Opacity: ${((100 * x) / 255).toFixed(0)}%`}
          onChange={(ev, alpha) => {
            if (typeof alpha !== 'number') {
              return;
            }

            const next: RGBAColor = [...value];
            next[3] = alpha;
            onChangeComplete(next);
          }}
        />
      </Box>
    </>
  );
};
