import { atom } from 'recoil';

export const playerAtom = atom<{
  playing: boolean;
  suspended: boolean;
  // timeOffset: number;
  animationSpeed: number;
  displaySettings: {
    trailLength: null | number;
    blendMode: 'normal' | 'neon';
    showMapLabels: boolean;
  };
}>({
  key: 'baseMap:player',
  default: {
    playing: false,
    suspended: false,
    animationSpeed: 500,
    displaySettings: {
      trailLength: 60 * 60,
      blendMode: 'neon',
      showMapLabels: true,
    },
  },
});
