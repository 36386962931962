import {
  Box,
  Button,
  CardProps,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useDatasetInformation } from './hooks';
import {
  DataPointIcon,
  DurationIcon,
  FlightIcon,
  StartIcon,
  AirlineIcon,
  DrawnPointIcon,
} from './StatisticsIcons';
import { intlFormat, formatDistance } from 'date-fns';

export const DatasetStatisticsPage: React.FC<{}> =
  function DatasetStatisticsPage() {
    const { statistics, metadata, loaded } = useDatasetInformation();

    function wrap(content: React.ReactElement) {
      return (
        <>
          <DialogTitle>Dataset statistics</DialogTitle>
          {content}
          <DialogActions>
            <Button color="inherit" component={RouterLink} to="/">
              Close
            </Button>
          </DialogActions>
        </>
      );
    }

    if (!loaded || !metadata) {
      return wrap(
        <DialogContent>
          <DialogContentText>No dataset loaded yet!</DialogContentText>
        </DialogContent>,
      );
    }

    return wrap(
      <DialogContent>
        <Grid
          container
          alignItems="stretch"
          spacing={1}
          columns={{ xs: 1, md: 3 }}
        >
          <Grid item xs={1}>
            <StatisticsCard
              title={
                <>
                  <FlightIcon fontSize="inherit" /> Total number of flights
                </>
              }
              statistic={statistics.flights.toLocaleString()}
            />
          </Grid>
          <Grid item xs={1}>
            <StatisticsCard
              title={
                <>
                  <AirlineIcon fontSize="inherit" /> Total number of airlines
                </>
              }
              statistic={Object.keys(
                statistics.countByAo,
              ).length.toLocaleString()}
            />
          </Grid>
          <Grid item xs={1}>
            <StatisticsCard
              title={
                <>
                  <DataPointIcon fontSize="inherit" /> Total number of points
                </>
              }
              statistic={statistics.totalDataPoints.toLocaleString()}
            />
          </Grid>
          <Grid item xs={1}>
            <StatisticsCard
              title={
                <>
                  <DrawnPointIcon fontSize="inherit" /> Total number of drawn
                  points
                </>
              }
              statistic={statistics.drawnDataPoints.toLocaleString()}
            />
          </Grid>
          <Grid item xs={1}>
            <StatisticsCard
              title={
                <>
                  <StartIcon fontSize="inherit" /> Start
                </>
              }
              statistic={intlFormat(metadata.start, {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZoneName: 'short',
                timeZone: 'UTC',
              })}
            />
          </Grid>
          <Grid item xs={1}>
            <StatisticsCard
              title={
                <>
                  <DurationIcon fontSize="inherit" /> Duration
                </>
              }
              statistic={formatDistance(0, metadata.duration * 1000)}
            />
          </Grid>
        </Grid>
      </DialogContent>,
    );
  };

const StatisticsCard: React.FC<
  {
    title: React.ReactNode;
    statistic: React.ReactNode;
  } & Omit<CardProps, 'children' | 'title'>
> = function StatisticsCard({ sx, title, statistic, children, ...rest }) {
  const theme = useTheme();

  return (
    <Paper sx={{ height: '100%', ...sx }} {...rest}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridTemplateRows: `${theme.spacing(4)} 1fr`,
          height: '100%',
          rowGap: theme.spacing(1),
          columnGap: theme.spacing(1),
          p: 1,
        }}
      >
        <Typography color="text.secondary" variant="overline">
          {title}
        </Typography>
        <Typography
          sx={{
            alignSelf: 'center',
            justifySelf: 'center',
          }}
          align="center"
          variant="h4"
        >
          {statistic}
        </Typography>
      </Box>
    </Paper>
  );
};
