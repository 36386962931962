import { Close as CloseIcon } from '@mui/icons-material';
import {
  Alert,
  Button,
  Collapse,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
} from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useList } from 'react-use';
import { ColorFilterEditionList } from './ColorFilterEditionList';
import { useColorFilters } from './useColorFilters';
import { createCatchAllFilter } from './utils';

export const ColorEditionPage: React.FC<{}> = function ColorEditionPage() {
  const [showPresetHint, setPresetHint] = useState(true);

  const [{ filters }, { setState: setColorFilters }] = useColorFilters();

  const [editionFilters, setEditionFilters_] = useList(filters);

  const setEditionFilters = useMemo(() => {
    const catchAllFilter = createCatchAllFilter('normal');

    const reset = () => {
      setEditionFilters_.set(filters);
    };

    const clear = () => {
      setEditionFilters_.set([catchAllFilter]);
    };

    return {
      ...setEditionFilters_,
      clear,
      reset,
    };
  }, [setEditionFilters_, filters]);

  const commitFilterEdition = useCallback(() => {
    setColorFilters((prev) => ({
      ...prev,
      filters: editionFilters,
    }));
  }, [editionFilters, setColorFilters]);

  return (
    <>
      <DialogTitle>
        Edit coloring
        <IconButton
          sx={{ position: 'absolute', right: 8, top: 8 }}
          component={RouterLink}
          to="/"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Collapse in={showPresetHint}>
          <Alert
            sx={{ mb: 2 }}
            severity="info"
            onClose={() => setPresetHint(false)}
          >
            No inspiration ?{' '}
            <Link component={RouterLink} to="/colors/presets">
              Take a look at our presets !
            </Link>
          </Alert>
        </Collapse>

        <ColorFilterEditionList
          filters={editionFilters}
          actions={setEditionFilters}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="inherit"
          onClick={() => {
            setEditionFilters.clear();
          }}
        >
          Clear
        </Button>
        <Button
          color="inherit"
          onClick={() => {
            setEditionFilters.reset();
          }}
        >
          Reset
        </Button>
        <Button color="inherit" component={RouterLink} to="/">
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="inherit"
          component={RouterLink}
          to="/"
          onClick={() => {
            commitFilterEdition();
          }}
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
};
