import { selector, useRecoilValue } from 'recoil';
import { dataStatisticsAtom } from './hooks';

const rankedAoSelector = selector<
  Array<{ prefix: string; commonName?: string; count: number }>
>({
  key: 'baseMap:rankedAos',
  get: ({ get }) => {
    const { countByAo } = get(dataStatisticsAtom);

    const sorted = Object.entries(countByAo)
      .map(([prefix, count]) => ({ prefix, count }))
      .sort((a, b) => b.count - a.count);

    return sorted;
  },
});

export function useRankedAircraftOperators() {
  const value = useRecoilValue(rankedAoSelector);

  return value;
}
