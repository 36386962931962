export const TOP_AIRCRAFT_OPERATORS = 30;
export const AO_GROUP_SIZE = 10;

export const INITIAL_VIEW_STATE = {
  longitude: 4,
  latitude: 46,
  zoom: 5,
  pitch: 0,
  bearing: 0,
};
