import { useIsMobile } from '../utils';
import { useTimeOffsetThrottled, usePlayback } from './hooks';
import { TinyText } from './TinyText';

export const PlayerTime: React.FC<{}> = function PlayerTime() {
  const { mediaQuery: isMobile } = useIsMobile();
  const timeOffset = useTimeOffsetThrottled(200);
  const [playerState] = usePlayback();

  return (
    <TinyText
      sx={{
        gridRow: 1,
        gridColumn: 3,
        pr: 2,
        mt: -1,
        justifySelf: 'end',
        alignSelf: 'start',
      }}
    >
      {!isMobile && (
        <>{timeOffsetToString(timeOffset, { offsetHours: -5 })} EST / </>
      )}
      {timeOffsetToString(timeOffset, {
        showSeconds: playerState.animationSpeed < 60,
      })}{' '}
      UTC
    </TinyText>
  );
};

export function timeOffsetToString(
  timeOffset: number,
  {
    showSeconds = false,
    offsetHours = 0,
  }: { showSeconds?: boolean; offsetHours?: number } = {},
): string {
  const hours = (Math.floor(timeOffset / 3600) + offsetHours + 24) % 24;
  const minutes = Math.floor(timeOffset / 60) % 60;
  const seconds = Math.floor(timeOffset) % 60;

  const hoursStr = hours.toString().padStart(2, '0');
  const minutesStr = minutes.toString().padStart(2, '0');

  const strParts = [hoursStr, minutesStr];

  if (showSeconds) {
    strParts.push(seconds.toString().padStart(2, '0'));
  }

  return strParts.join(':');
}
