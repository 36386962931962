import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';
import { tuple } from '../utils';
import { INITIAL_VIEW_STATE } from './constants';
import { FlyToInterpolator } from '@deck.gl/core';
import type { ViewStateProps as ViewStateWithInterpolator } from '@deck.gl/core/lib/deck';

type RequiredSome<T, U extends keyof T> = Required<Pick<T, U>> & Omit<T, U>;

export type StoredViewState = RequiredSome<
  ViewStateWithInterpolator,
  'latitude' | 'longitude' | 'zoom' | 'pitch' | 'bearing'
>;

type ICtx = [
  {
    viewStateRef: React.MutableRefObject<undefined | StoredViewState>;
    initialViewState: StoredViewState;
    viewState: StoredViewState;
  },
  {
    saveCurrentViewState: (vs: StoredViewState) => void;
    setInitialViewState: (
      vs: StoredViewState | ((vs: StoredViewState) => StoredViewState),
    ) => void;
    flyTo: (
      vs: StoredViewState | ((vs: StoredViewState) => StoredViewState),
      duration?: number,
    ) => void;
    resetCamera: () => void;
  },
];

export const ViewStateCtx = createContext<null | ICtx>(null);

export const CurrentViewStateProvider: React.FC<{}> =
  function CurrentViewStateProvider({ children }) {
    const currentViewStateRef = useRef<undefined | StoredViewState>();
    const [initialViewState, setInitialViewState] =
      useState<StoredViewState>(INITIAL_VIEW_STATE);

    const [viewState, setViewState] =
      useState<StoredViewState>(initialViewState);

    const saveCurrentViewState = useCallback(
      (viewState: StoredViewState) => {
        currentViewStateRef.current = viewState;
      },
      [currentViewStateRef],
    );

    const flyTo = useCallback(
      (
        viewState:
          | StoredViewState
          | ((viewState: StoredViewState) => StoredViewState),
        duration: number = 300,
      ) => {
        const transition = new FlyToInterpolator();
        const transitionDuration = duration;

        setViewState((prev) => {
          let newViewState =
            typeof viewState === 'function' ? viewState(prev) : viewState;

          const r = {
            ...prev,
            ...newViewState,
            transition,
            transitionDuration,
          };

          return r;
        });
      },
      [setViewState],
    );

    // When initialview state changes, flyTo
    // useEffect(() => {
    //   if (initialViewState) {
    //     flyTo(initialViewState);
    //   }
    // }, [flyTo, initialViewState]);

    const resetCamera = useCallback(() => {
      flyTo((prev) => ({ ...initialViewState }));
    }, [flyTo, initialViewState]);

    const ctx: ICtx = useMemo(
      () =>
        tuple(
          { viewStateRef: currentViewStateRef, initialViewState, viewState },
          { saveCurrentViewState, setInitialViewState, resetCamera, flyTo },
        ),
      [
        currentViewStateRef,
        saveCurrentViewState,
        initialViewState,
        setInitialViewState,
        viewState,
        resetCamera,
        flyTo,
      ],
    );

    return (
      <ViewStateCtx.Provider value={ctx}>{children}</ViewStateCtx.Provider>
    );
  };

export function useGlobalViewState(): ICtx {
  const ctx = useContext(ViewStateCtx);

  if (!ctx) {
    throw new Error(
      `[useGlobalViewState]: This hook must be used in a descendant of <CurrentViewStateProvider>`,
    );
  }

  return ctx;
}
