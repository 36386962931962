import { Airlines, HeadsetMic, Height, Elevator } from '@mui/icons-material';
import { SvgIconProps, Box } from '@mui/material';
import { forwardRef } from 'react';
import Airport from '../Player/AirportIcon';
import type { ColorFilterState } from './types';

export const ColorFilterIcon = forwardRef<
  React.ComponentRef<typeof Airlines>,
  SvgIconProps & { type: ColorFilterState['type'] }
>(function ColorFilterIcon({ type, ...props }, innerRef) {
  switch (type) {
    case 'aircraftOperator':
      return <Airlines ref={innerRef} {...props} />;
    case 'callsign':
      return <HeadsetMic ref={innerRef} {...props} />;
    case 'airport':
      return <Airport ref={innerRef} {...props} />;
    case 'altitudeGradient':
      return <Height ref={innerRef} {...props} />;
    case 'verticalSpeed': {
      return <Elevator ref={innerRef} {...props} />;
    }
    case 'catchAll':
    default:
      return null;
  }
});
