import { Chip, ChipProps, ThemeProvider } from '@mui/material';
import { forwardRef } from 'react';
import {
  ColorFilterIcon,
  ColorFilterState,
  useColorFilterMuiTheme,
} from '../colors';

export const FilterChip = forwardRef<
  React.ComponentRef<typeof Chip>,
  ChipProps & { filter: ColorFilterState }
>(function FilterChip({ filter, sx, ...rest }, ref) {
  const { theme, gradient } = useColorFilterMuiTheme({ filter });

  const label = rest.label ?? filter.displayName;

  return (
    <ThemeProvider theme={theme}>
      <Chip
        ref={ref}
        {...rest}
        sx={{
          background: gradient,
          ...sx,
        }}
        label={label}
        icon={rest.icon ?? <ColorFilterIcon type={filter.type} />}
        color={!gradient ? 'primary' : undefined}
      />
    </ThemeProvider>
  );
});
