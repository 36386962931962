import { schemeTableau10 } from 'd3-scale-chromatic';
import { decomposeColor } from '@mui/system/colorManipulator';
import { ftToM, tuple } from '../utils';
import type {
  ColorFilterState,
  ICatchAllFilter,
  TrajectoryMetadata,
  TrajectoryPlot,
  RGBAColor,
} from './types';
import { v4 } from 'uuid';
import { FLAT_UI_COLORS, TRIP_OPACITY, MUTED_TRIP_OPACITY } from './palette';

export const BASE_PALETTE_STRING = schemeTableau10.slice();

export const BASE_PALETTE = BASE_PALETTE_STRING.map((colorString) => {
  const c = decomposeColor(colorString);
  return tuple(c.values[0], c.values[1], c.values[2]);
});

export const VERTICAL_SPEED_COLORS = {
  level: [...FLAT_UI_COLORS.amethyst, TRIP_OPACITY] as RGBAColor,
  climb: [...FLAT_UI_COLORS.peterriver, TRIP_OPACITY] as RGBAColor,
  descend: [...FLAT_UI_COLORS.carrot, TRIP_OPACITY] as RGBAColor,
};

export function getRandomPaletteColor(
  opacity: number = TRIP_OPACITY,
): RGBAColor {
  const idx = Math.floor(Math.random() * BASE_PALETTE.length);
  const c = BASE_PALETTE[idx];
  return [...c, opacity];
}

export function createColorPalette({
  count,
  offset = 0,
  opacity = TRIP_OPACITY,
}: {
  count: number;
  offset?: number;
  opacity?: number;
}): Array<RGBAColor> {
  const palette: Array<RGBAColor> = [];

  for (let i = 0; i < count; i++) {
    const color: RGBAColor = [
      ...BASE_PALETTE[(i + offset) % BASE_PALETTE.length],
      opacity,
    ];
    palette.push(color);
  }

  return palette;
}

const CATCH_ALL_NORMAL: ColorFilterState & { type: 'catchAll' } = {
  id: v4(),
  type: 'catchAll',
  enabled: true,
  displayName: 'All flights',
  color: [...FLAT_UI_COLORS.amethyst, TRIP_OPACITY],
};

const CATCH_ALL_MUTED: ColorFilterState & { type: 'catchAll' } = {
  id: v4(),
  type: 'catchAll',
  enabled: true,
  displayName: 'Others',
  color: [0x66, 0x66, 0x66, MUTED_TRIP_OPACITY],
};

export function createCatchAllFilter(
  colorOrMode: 'normal' | 'muted' | RGBAColor,
  { enabled = true }: { enabled?: boolean } = {},
): ICatchAllFilter {
  const id = v4();

  if (typeof colorOrMode === 'string') {
    if (colorOrMode === 'normal') {
      return { ...CATCH_ALL_NORMAL, id, enabled };
    }

    return { ...CATCH_ALL_MUTED, id, enabled };
  }

  return { ...CATCH_ALL_NORMAL, id, color: colorOrMode, enabled };
}
