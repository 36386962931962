import { Close } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { formatDistance, intlFormat } from 'date-fns';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { atom } from 'recoil';
import { useAvailableDatasets } from './useAvailableDatasets';
import { useDatasetLoader, useIsDatasetSelected } from './hooks';
import { useIsMobile } from '../utils';
import {
  useQualitySettings,
  PERFORMANCE_PRESETS,
  PerformancePresetName,
} from './usePerformancePresets';

import {
  CoverageIcon,
  DurationIcon,
  FlightIcon,
  SizeIcon,
  StartIcon,
} from './StatisticsIcons';

export const dataloadSettingsDialogAtom = atom<boolean>({
  key: 'dataloadSettings:dialogOpen',
  default: false,
});

export const DataloadSettings: React.FC<{}> = function DataloadSettings() {
  const theme = useTheme();
  const { mediaQuery: isMobileLayout } = useIsMobile();

  const [, { selectDataset }] = useDatasetLoader();

  const [datasets] = useAvailableDatasets();

  const isFirstLoad = !useIsDatasetSelected();

  const [datasetIdx, setSelectedDatasetIdx] = useState<number>(0);
  const selectedDataset = datasets[datasetIdx];

  const [performanceProfile, setPerformanceProfile] =
    useState<PerformancePresetName>('medium');

  const [quality, qualityActions] = useQualitySettings({
    datasetPlots: selectedDataset.plots,
    qualityPreset: performanceProfile,
  });

  useEffect(() => {
    setPerformanceProfile(quality.recommendedPreset);
  }, [
    quality.detectionResults,
    quality.recommendedPreset,
    setPerformanceProfile,
  ]);

  return (
    <>
      <DialogTitle>
        {isFirstLoad ? 'Pick a dataset' : 'Data loading settings'}
        {!isFirstLoad && (
          <IconButton
            sx={{ position: 'absolute', right: 8, top: 8 }}
            component={RouterLink}
            to="/"
          >
            <Close />
          </IconButton>
        )}
      </DialogTitle>
      <Divider />
      <DialogContent>
        {isFirstLoad && (
          <>
            <Alert severity="info">
              Hello ! This website lets you visualize what's going on in the sky
              above your head. Start by picking one of the available datasets,
              then click on the <em>start</em> button below.
            </Alert>
          </>
        )}
        <Box
          sx={{
            mt: 1,
            mb: 0,
            mr: -1,
            display: 'grid',
            gridTemplateColumns: isMobileLayout ? '1fr' : 'repeat(3, 1fr)',
          }}
        >
          {datasets.map((d, idx) => {
            const targetSampleRate = qualityActions.getSampleRateForData(
              d.plots,
            );

            const performanceWarning = (() => {
              if (targetSampleRate < 6) {
                return;
              }

              if (targetSampleRate < 12) {
                return 'warning' as const;
              }

              return 'error' as const;
            })();

            const color = (() => {
              if (idx !== datasetIdx) {
                return undefined;
              }

              switch (performanceWarning) {
                case 'warning':
                  return 'warning';
                case 'error':
                  return 'error';
                default:
                  return 'info';
              }
            })();

            return (
              <Card
                sx={{
                  mr: 1,
                  mb: 1,
                  borderColor: color ? `${color}.main` : undefined,
                  display: 'flex',
                  flexDirection: 'column',
                  minWidth: theme.spacing(24),
                }}
                variant="outlined"
                key={idx}
              >
                <CardActionArea
                  sx={{
                    display: 'flex',
                    alignItems: 'stretch',
                    justifyContent: 'flex-start',
                    flexGrow: 1,
                    flexShrink: 0,
                    flexDirection: 'column',
                  }}
                  onClick={() => {
                    setSelectedDatasetIdx(idx);
                  }}
                >
                  <CardHeader
                    title={d.name}
                    action={
                      <Checkbox
                        color={color}
                        disableRipple
                        checked={idx === datasetIdx}
                      />
                    }
                  />
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: 1,
                      flexShrink: 0,
                    }}
                  >
                    <Alert severity={performanceWarning ?? 'success'}>
                      {!performanceWarning ? (
                        <>
                          Trajectory precision will be{' '}
                          {targetSampleRate === 1 ? 'perfect' : 'good'} (1 plot
                          every {10 * targetSampleRate}s).
                        </>
                      ) : performanceWarning === 'warning' ? (
                        <>
                          Trajectory precision will be mediocre (1 plot every{' '}
                          {10 * targetSampleRate}s).
                        </>
                      ) : performanceWarning === 'error' ? (
                        <>
                          This dataset is too large for the selected performance
                          profile (1 plot every {10 * targetSampleRate}
                          s).
                        </>
                      ) : (
                        ''
                      )}
                    </Alert>

                    <Typography sx={{ mb: 4 }}>{d.description}</Typography>
                    <Box
                      sx={{
                        mt: 'auto',
                        display: 'grid',
                        gridTemplateColumns: `${theme.spacing(4)} 1fr`,
                        gridGap: theme.spacing(1),
                        gridRowGap: theme.spacing(2),
                        mb: -1,
                      }}
                    >
                      <Tooltip title="Coverage">
                        <CoverageIcon
                          fontSize="small"
                          sx={{ gridColumn: 1, color: 'text.secondary' }}
                        />
                      </Tooltip>
                      <Box sx={{ gridColumn: 2 }}>
                        <Typography variant="subtitle2">
                          {d.coverage}
                        </Typography>
                      </Box>

                      <Tooltip title="Start">
                        <StartIcon
                          fontSize="small"
                          sx={{ gridColumn: 1, color: 'text.secondary' }}
                        />
                      </Tooltip>
                      <Box sx={{ gridColumn: 2 }}>
                        <Typography variant="subtitle2">
                          {intlFormat(d.start, {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            timeZoneName: 'short',
                            timeZone: 'UTC',
                          })}
                        </Typography>
                      </Box>

                      <Tooltip title="Duration">
                        <DurationIcon
                          fontSize="small"
                          sx={{ gridColumn: 1, color: 'text.secondary' }}
                        />
                      </Tooltip>
                      <Box sx={{ gridColumn: 2 }}>
                        <Typography variant="subtitle2">
                          {formatDistance(0, d.duration * 1000)}
                        </Typography>
                      </Box>

                      <Tooltip title="Download size">
                        <SizeIcon
                          fontSize="small"
                          sx={{ gridColumn: 1, color: 'text.secondary' }}
                        />
                      </Tooltip>
                      <Box sx={{ gridColumn: 2 }}>
                        <Typography variant="subtitle2">
                          {Math.floor(d.size / 1024)} MB
                        </Typography>
                      </Box>

                      <Tooltip title="Number of flights">
                        <FlightIcon
                          fontSize="small"
                          sx={{ gridColumn: 1, color: 'text.secondary' }}
                        />
                      </Tooltip>
                      <Box sx={{ gridColumn: 2 }}>
                        <Typography variant="subtitle2">
                          {d.trajectories > 1000
                            ? `${Math.floor(d.trajectories / 1000)}k`
                            : d.trajectories}{' '}
                          flights
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            );
          })}
        </Box>
      </DialogContent>
      <Divider />
      <DialogContent sx={{ flexShrink: 0 }}>
        <TextField
          id="qualityPreset"
          margin="none"
          select
          fullWidth={isMobileLayout}
          sx={{ minWidth: 250 }}
          label="Performance profile"
          disabled={quality.loading}
          value={performanceProfile}
          helperText={
            <>
              <Link
                component="button"
                disabled={quality.loading}
                color="text.secondary"
                onClick={() => {
                  qualityActions.runDetection();
                }}
              >
                Auto detect ?
              </Link>
            </>
          }
          size="small"
          onChange={(ev) => {
            const newValue = ev.target.value as PerformancePresetName;
            setPerformanceProfile(newValue);
          }}
        >
          {Object.keys(PERFORMANCE_PRESETS).map((value) => {
            return (
              <MenuItem key={value} value={value}>
                {value.toUpperCase()}
              </MenuItem>
            );
          })}
        </TextField>
      </DialogContent>
      <Divider />
      <DialogActions>
        {!isFirstLoad && (
          <Button color="inherit" component={RouterLink} to="/">
            Close
          </Button>
        )}
        <Button
          variant="outlined"
          color="inherit"
          component={RouterLink}
          to="/"
          onClick={() => {
            const selectedDataset = datasets[datasetIdx];
            const dataset = {
              id: selectedDataset.id,
              name: selectedDataset.name,
              url: selectedDataset.url,
              duration: selectedDataset.duration,
              coverageGeoJSON: (selectedDataset.coverageGeoJSON as any) ?? null,
              includesDepDest: !!selectedDataset.includesDepDest,
              start: selectedDataset.start,
              trajectoryCount: selectedDataset.trajectories,
            };

            selectDataset({
              dataset,
              loadOptions: {
                batchSize: 2000,
                hardLimit: null,
                sampleRate:
                  quality.targetSampleRate ??
                  qualityActions.getSampleRateForData(selectedDataset.plots),
                resetCamera: true,
              },
            });
          }}
        >
          {isFirstLoad ? 'Start' : 'Reload'}
        </Button>
      </DialogActions>
    </>
  );
};

export default DataloadSettings;
