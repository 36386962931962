import {
  Assessment as StatisticsIcon,
  Collections as PresetIcon,
  Edit,
  History as ResetViewIcon,
  InvertColors,
  Place,
  Settings,
  Shuffle as SwitchIcon,
} from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import { Link as RouterLink } from 'react-router-dom';
import { usePlayback } from './hooks';
import { useColorFilters } from '../colors';
import { useGlobalViewState } from '../Map';
import { useIsMobile } from '../utils';
import { FilterChip } from './FilterChip';
import { ColorPaletteIcon } from './icons';
import { memo } from 'react';

export const MapKey: React.FC<{ disabled: boolean }> = memo(function MapKey({
  disabled,
}) {
  const [{ filters }, { toggleFilter, invertFilters }] = useColorFilters();
  const { mediaQuery: isMobile } = useIsMobile();
  const settingsMenuState = usePopupState({
    popupId: 'navigation-menu',
    variant: 'popover',
  });

  const [, { resetCamera }] = useGlobalViewState();
  const [playerState, { toggleMapLabels }] = usePlayback();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexShrink: 1,
          minWidth: 0,
          alignItems: isMobile ? 'center' : 'start',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexShrink: 1,
            flexGrow: 1,
            overflowX: isMobile ? 'auto' : undefined,
            flexWrap: isMobile ? 'nowrap' : 'wrap',
            mt: isMobile ? 1 : 0,
            ml: 1,
            mr: 1,
            mb: isMobile ? 0 : -1,
          }}
        >
          {filters.map((f, idx) => {
            return (
              <FilterChip
                filter={f}
                sx={{
                  mr: 1,
                  mb: 1,
                }}
                size="small"
                disabled={disabled}
                variant={f.enabled ? 'filled' : 'outlined'}
                onClick={
                  filters.length > 1
                    ? () => {
                        toggleFilter(idx);
                      }
                    : undefined
                }
                key={f.id}
              />
            );
          })}
        </Box>
        {/**
         *
         * On mobile, we only want a single menu icon.
         *
         * On desktop, we have more room
         */}
        <Box sx={{ flexShrink: 0, pr: 1 }}>
          {!isMobile && (
            <Tooltip placement="top" title="Edit colors">
              <IconButton
                disabled={disabled}
                component={RouterLink}
                to="colors/edit"
                size="small"
              >
                <Edit />
              </IconButton>
            </Tooltip>
          )}
          {!isMobile && (
            <Tooltip placement="top" title="Color presets">
              <IconButton
                disabled={disabled}
                component={RouterLink}
                to="colors/presets"
                size="small"
              >
                <PresetIcon />
              </IconButton>
            </Tooltip>
          )}
          <IconButton
            disabled={disabled}
            size="small"
            {...bindTrigger(settingsMenuState)}
          >
            <Settings />
          </IconButton>
        </Box>
      </Box>

      <Menu
        {...bindMenu(settingsMenuState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <MenuItem
          component={RouterLink}
          to="/colors/edit"
          onClick={() => {
            settingsMenuState.close();
          }}
        >
          <ListItemIcon>
            <ColorPaletteIcon fontSize={isMobile ? 'small' : undefined} />
          </ListItemIcon>
          Edit colors
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to="/colors/presets"
          onClick={() => {
            settingsMenuState.close();
          }}
        >
          <ListItemIcon>
            <PresetIcon />
          </ListItemIcon>
          Color presets
        </MenuItem>
        <MenuItem
          disabled={filters.length < 2}
          onClick={() => {
            invertFilters();
            settingsMenuState.close();
          }}
        >
          <ListItemIcon>
            <InvertColors />
          </ListItemIcon>
          Invert colors
        </MenuItem>

        <Divider />

        <MenuItem
          onClick={() => {
            settingsMenuState.close();
            toggleMapLabels();
          }}
        >
          <ListItemIcon>
            <Place fontSize={isMobile ? 'small' : undefined} />
          </ListItemIcon>
          {playerState.displaySettings.showMapLabels ? 'Hide' : 'Show'} map
          labels
        </MenuItem>
        <MenuItem
          onClick={() => {
            settingsMenuState.close();
            resetCamera();
          }}
        >
          <ListItemIcon>
            <ResetViewIcon fontSize={isMobile ? 'small' : undefined} />
          </ListItemIcon>
          Reset camera
        </MenuItem>

        <Divider />

        <MenuItem
          component={RouterLink}
          to="/settings"
          onClick={() => {
            settingsMenuState.close();
          }}
        >
          <ListItemIcon>
            <SwitchIcon fontSize={isMobile ? 'small' : undefined} />
          </ListItemIcon>
          Switch dataset
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to="/statistics"
          onClick={() => {
            settingsMenuState.close();
          }}
        >
          <ListItemIcon>
            <StatisticsIcon fontSize={isMobile ? 'small' : undefined} />
          </ListItemIcon>
          Dataset statistics
        </MenuItem>
      </Menu>
    </>
  );
});
