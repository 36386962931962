import { lazy, Suspense } from 'react';

const BaseMap = lazy(() => import('./BaseMap'));

export const MapLazy: React.FC<{}> = function MapLazy() {
  return (
    <Suspense fallback={<div>Loading ...</div>}>
      <BaseMap />
    </Suspense>
  );
};

export default MapLazy;
