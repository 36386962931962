type RGBColor = [number, number, number];

export const MUI_COLORS = {
  orange: [0xf5, 0x7c, 0x20] as RGBColor,
  cyan: [0x00, 0xbc, 0xd4] as RGBColor,
  red: [0xf4, 0x43, 0x36] as RGBColor,
  indigo: [0x5c, 0x6b, 0xc0] as RGBColor,
  amber: [0xff, 0xc1, 0x07] as RGBColor,
  purple: [0x9c, 0x27, 0xb0] as RGBColor,
  green: [0x4c, 0xaf, 0x50] as RGBColor,
  deepOrange: [0xff, 0x57, 0x22] as RGBColor,
  pink: [0xe9, 0x1e, 0x63] as RGBColor,
  yellow: [0xff, 0xeb, 0x3b] as RGBColor,
  lightBlue: [0x03, 0xa9, 0xf4] as RGBColor,
  deepPurple: [0x67, 0x3a, 0xb7] as RGBColor,
  lightGreen: [0x8b, 0xc3, 0xa4] as RGBColor,
  teal: [0x00, 0x96, 0x88] as RGBColor,
  blue: [0x21, 0x96, 0xf3] as RGBColor,
  lime: [0xcd, 0xdc, 0x39] as RGBColor,
};

export const FLAT_UI_COLORS = {
  greensea: [0x16, 0xa0, 0x85] as RGBColor,
  sunflower: [0xf1, 0xc4, 0x0f] as RGBColor,
  peterriver: [0x34, 0x98, 0xdb] as RGBColor,
  alizarin: [0xe7, 0x4c, 0x3c] as RGBColor,
  emerland: [0x2e, 0xcc, 0x71] as RGBColor,
  amethyst: [0x9b, 0x59, 0xb6] as RGBColor,
  carrot: [0xe6, 0x7e, 0x22] as RGBColor,
};

export const TRIP_OPACITY = 0xa0;
export const MUTED_TRIP_OPACITY = 0x8;
