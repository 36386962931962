import React, { lazy, Suspense } from 'react';

const SharePage = lazy(() => import('./SharePage'));

export const SharePageLazy: React.FC<React.ComponentProps<typeof SharePage>> =
  function SharePageLazy(props) {
    return (
      <Suspense fallback={<div>Loading ...</div>}>
        <SharePage {...props} />
      </Suspense>
    );
  };

export default SharePageLazy;
