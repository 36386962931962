import { playerAtom } from './state';
import {
  atom,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { useCallback } from 'react';
import { tuple } from '../utils';
import { useThrottle } from 'react-use';

const timeOffsetAtom = atom<number>({
  key: 'player:timeOffset',
  default: 3600,
});

export function useTimeOffset() {
  return useRecoilValue(timeOffsetAtom);
}

export function useTimeOffsetThrottled(ms: number = 200) {
  const timeOffset = useTimeOffset();
  return useThrottle(timeOffset, ms);
}

export function useSetTimeOffset() {
  return useSetRecoilState(timeOffsetAtom);
}

export function usePlayback() {
  const [playerState, setPlayerState] = useRecoilState(playerAtom);
  const setTimeOffset = useSetRecoilState(timeOffsetAtom);

  /**
   * Two callbacks to control the suspended state of the animation
   */
  const suspend = useCallback(
    () =>
      setPlayerState((prev) => {
        if (prev.suspended === true) {
          return prev;
        }

        return { ...prev, suspended: true };
      }),
    [setPlayerState],
  );

  const resume = useCallback(
    () =>
      setPlayerState((prev) => {
        if (prev.suspended === false) {
          return prev;
        }

        return { ...prev, suspended: false };
      }),
    [setPlayerState],
  );

  const toggleMapLabels = useCallback(() => {
    setPlayerState((prev) => ({
      ...prev,
      displaySettings: {
        ...prev.displaySettings,
        showMapLabels: !prev.displaySettings.showMapLabels,
      },
    }));
  }, [setPlayerState]);

  return tuple(playerState, {
    resume,
    suspend,
    setTimeOffset,
    setPlayerState,
    toggleMapLabels,
  });
}
