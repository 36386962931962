import { Info as AboutIcon } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Link,
  Toolbar,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import {
  Link as RouterLink,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useOutlet,
} from 'react-router-dom';
import { AboutPage } from './About';
import {
  ColorBufferProvider,
  ColorEditionPage,
  PresetSelectionPage,
} from './colors';
import { DatasetStatisticsPage, LoaderInformation } from './Loader';
import DataloadSettings from './Loader/DataloadSettings';
import { MapLazy as BaseMap } from './Map';
import { PlayerControlMainPanel, usePlayback } from './Player';
import { SplashPage } from './Splash';

/**
 * Try to code split those two
 */
import ShareLanding from './Share/ShareLandingLazy';
import SharePageLazy from './Share/SharePageLazy';

const buildId = import.meta.env.VITE_BUILD_ID;

export const App: React.FC<{}> = function App() {
  const navigate = useNavigate();
  const [showSplash, setShowSplash] = useState(true);

  return (
    <>
      <ColorBufferProvider />
      <Routes>
        <Route path="*" element={<DialogPage disableTransition={showSplash} />}>
          <Route
            path="s"
            element={
              <ShareLanding onLoadShareable={() => setShowSplash(false)} />
            }
          />

          {showSplash ? (
            <>
              <Route
                index
                element={
                  <SplashPage
                    onNext={() => {
                      setShowSplash(false);
                      navigate('settings');
                    }}
                  />
                }
              />
              <Route path="*" element={<Navigate to="/" />} />
            </>
          ) : (
            <>
              <Route path="about" element={<AboutPage />} />
              <Route path="statistics" element={<DatasetStatisticsPage />} />
              <Route path="share" element={<SharePageLazy />} />
              <Route path="settings" element={<DataloadSettings />} />
              <Route path="colors/presets" element={<PresetSelectionPage />} />
              <Route path="colors/edit" element={<ColorEditionPage />} />
              {/* <Route path="share" element={<SharePage />} /> */}
              <Route
                path=":anything"
                element={
                  <>
                    <DialogContent>Page not found !</DialogContent>
                    <DialogActions>
                      <Button component={RouterLink} to="/">
                        Close
                      </Button>
                    </DialogActions>
                  </>
                }
              />
            </>
          )}
        </Route>
      </Routes>
      <Container
        disableGutters
        maxWidth={false}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          maxHeight: '100%',
        }}
      >
        <AppBar component="header" enableColorOnDark position="static">
          <Toolbar>
            <Typography sx={{ flexGrow: 1 }} variant="h6" component="div">
              Above our heads
            </Typography>
            <IconButton component={RouterLink} to="/about" color="inherit">
              <AboutIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box
          flexGrow={1}
          flexShrink={1}
          display="flex"
          flexDirection="column"
          minHeight={100}
        >
          <Box flexGrow={1} flexShrink={1} sx={{ position: 'relative' }}>
            <BaseMap />
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <LoaderInformation />
            </Box>
          </Box>
        </Box>
        <Box position="relative">
          <PlayerControlMainPanel />
        </Box>
        <AppBar elevation={0} position="static" component="footer">
          <Typography
            variant="overline"
            color="text.disabled"
            textAlign="right"
            fontSize={9}
          >
            Build {buildId ?? 'unknown'} -{' '}
            <Link component={RouterLink} to="about" underline="hover">
              Created by Benjamin Beret
            </Link>
          </Typography>
        </AppBar>
      </Container>
    </>
  );
};

const DialogPage: React.FC<{ disableTransition?: boolean }> =
  function DialogPage({ disableTransition = false }) {
    const outlet = useOutlet();
    const location = useLocation();
    const keyRef = useRef(location.key);
    const navigate = useNavigate();
    const dialogOpen = !!outlet;

    const [, { resume, suspend }] = usePlayback();

    /**
     * When a page is open, suspend playback.
     *
     * Resume when the page is closed.
     */
    useEffect(() => {
      if (dialogOpen) {
        suspend();
        return;
      }

      resume();
      return;
    }, [dialogOpen, resume, suspend]);

    /**
     * When transitioning to a dialog-less page, dialogOpen will be set to false.
     *
     * In this case, we need to keep current outlet until the closing animation finishes.
     */
    if (location.key && dialogOpen) {
      keyRef.current = location.key;
    }

    return (
      <Dialog
        open={dialogOpen}
        fullWidth
        maxWidth="md"
        scroll="paper"
        transitionDuration={disableTransition ? 0 : undefined}
        onClose={(ev, reason) => {
          if (reason === 'backdropClick') {
            return;
          }

          navigate('/');
        }}
      >
        <AnimatedOutlet key={keyRef.current} />
      </Dialog>
    );
  };

const AnimatedOutlet: React.FC<{}> = function AnimatedOutlet() {
  const outlet = useOutlet();
  let [context] = useState(outlet);

  return context;
};

export default App;
