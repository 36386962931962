import {
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  Link,
  Typography,
  Divider,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export const AboutPage: React.FC<{}> = function AboutPage() {
  return (
    <>
      <DialogContent>
        <Paragraph title="Hello !">
          <DialogContentText>
            My name is{' '}
            <Link
              href="https://www.linkedin.com/in/benjaminberet/"
              target="_blank"
              rel="noopener"
              color="primary.light"
            >
              Benjamin Beret
            </Link>
            . I'm a former air traffic controller, now software engineer,
            working for{' '}
            <Link
              href="https://en.wikipedia.org/wiki/Direction_des_Services_de_la_navigation_a%C3%A9rienne"
              target="_blank"
              rel="noopener"
              color="primary.light"
            >
              the french air navigation service provider: DSNA.
            </Link>
          </DialogContentText>
          <DialogContentText>
            I built this website as a side project to convey the complexity of
            air traffic. Plus, it looks pretty nice ! &#128516;
          </DialogContentText>
        </Paragraph>

        <Paragraph title="Where does the data come from ?">
          <DialogContentText>
            Every dataset comes from{' '}
            <Link
              href="https://opensky-network.org/"
              target="_blank"
              rel="noopener"
              color="primary.light"
            >
              the Opensky Network
            </Link>
            .
          </DialogContentText>
        </Paragraph>

        <Paragraph title="Technology">
          <DialogContentText>
            Built with{' '}
            <Link
              href="https://reactjs.org/"
              target="_blank"
              rel="noopener"
              color="primary.light"
            >
              React
            </Link>
            ,{' '}
            <Link
              href="https://deck.gl/"
              target="_blank"
              rel="noopener"
              color="primary.light"
            >
              deck.gl
            </Link>
            ,{' '}
            <Link
              href="https://mui.com/"
              target="_blank"
              rel="noopener"
              color="primary.light"
            >
              MUI
            </Link>
            .
          </DialogContentText>
        </Paragraph>

        <Paragraph title="Questions ? Like it ? Hate it ?" last>
          <DialogContentText>
            Feel free to contact me:{' '}
            <Link
              href="mailto:ben@aboveourheads.org"
              rel="noopener"
              target="_blank"
              color="primary.light"
            >
              ben@aboveourheads.org
            </Link>
          </DialogContentText>
        </Paragraph>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button component={RouterLink} to="/" color="inherit">
          Close
        </Button>
      </DialogActions>
    </>
  );
};

const Paragraph: React.FC<{
  title: React.ReactNode;
  last?: boolean;
}> = ({ title, children, last }) => {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      {children}
      {!last && <Divider sx={{ mb: 2, mt: 2 }} />}
    </>
  );
};
