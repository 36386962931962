import { Check } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  CardActionArea,
  Collapse,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Link,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useCookieConsent } from './analytics';
// @ts-ignore
import previewVideo from './preview.mp4';
import { useIsMobile } from './utils';
import { CookieInfo } from './Cookies';

export const SplashPage: React.FC<{ onNext: () => void }> =
  function SplashPage({ onNext }) {
    const [{ consent }, cookieConsentActions] = useCookieConsent();
    const [cookieConsentPanelExpanded, setCookieConsentPanelExpanded] =
      useState(false);

    const { ua: isMobile } = useIsMobile();

    return (
      <>
        <DialogTitle>Welcome !</DialogTitle>
        <DialogContent sx={{ flexShrink: 8, pt: 0, pb: 0 }}>
          <Collapse sx={{ pb: 1, pt: 1 }} in={!cookieConsentPanelExpanded}>
            <DialogContentText gutterBottom>
              This website lets you explore 24 hours of recorded flight tracking
              data.
            </DialogContentText>
            <video
              width="100%"
              muted
              loop
              autoPlay
              disablePictureInPicture
              disableRemotePlayback
              playsInline
              controlsList="nodownload nofullscreen"
              src={previewVideo}
            />

            {isMobile && (
              <Alert severity="warning">
                You're viewing this website on a mobile device and this is not
                ideal. Panning and zooming might be slow, or worse, your browser
                may crash.
              </Alert>
            )}
          </Collapse>
        </DialogContent>
        <Divider />
        {consent === null && (
          <>
            <CardActionArea
              onClick={() => {
                setCookieConsentPanelExpanded((prev) => !prev);
              }}
            >
              <DialogContent sx={{ pt: 1, pb: 1 }}>
                <Typography sx={{ display: 'block' }} variant="caption">
                  This website uses cookies for analytics purposes.{' '}
                  <Link href="#" color="inherit">
                    Read more
                  </Link>
                </Typography>
              </DialogContent>
            </CardActionArea>

            <DialogContent sx={{ flexShrink: 1, pt: 0, pb: 0 }}>
              <Collapse sx={{ pt: 1, pb: 1 }} in={cookieConsentPanelExpanded}>
                <CookieInfo />
              </Collapse>
            </DialogContent>
          </>
        )}
        <DialogActions>
          {consent === null ? (
            <>
              <Button
                color="inherit"
                variant="outlined"
                onClick={() => {
                  onNext();
                }}
              >
                Reject
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  cookieConsentActions.accept();
                  onNext();
                }}
              >
                Accept and continue
              </Button>
            </>
          ) : (
            <>
              <Typography sx={{ mr: 1 }} variant="caption">
                <Check fontSize="inherit" color="success" /> Cookies{' '}
                <Link
                  href="#"
                  onClick={() => cookieConsentActions.reset()}
                  color="text.secondary"
                  sx={{
                    textDecorationStyle: 'dashed',
                  }}
                >
                  Reset choice ?
                </Link>
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Button
                color="inherit"
                variant="outlined"
                onClick={() => {
                  // Extend cookie life
                  cookieConsentActions.accept();
                  onNext();
                }}
              >
                Continue
              </Button>
            </>
          )}
        </DialogActions>
      </>
    );
  };
