import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { CookiesProvider } from 'react-cookie';
// import reportWebVitals from './reportWebVitals';
import { CssBaseline, GlobalStyles } from '@mui/material';
import '@fontsource/roboto';
import { ThemeProvider } from './Theme';
import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';
import { AnalyticsProvider } from './analytics';
import { CurrentViewStateProvider } from './Map';

ReactDOM.render(
  <StrictMode>
    <CookiesProvider>
      <BrowserRouter>
        <AnalyticsProvider>
          <ThemeProvider>
            <GlobalStyles
              styles={{
                'html, body, #root': {
                  width: '100%',
                  height: '100%',
                },
              }}
            />
            <CssBaseline />
            <RecoilRoot>
              <CurrentViewStateProvider>
                <App />
              </CurrentViewStateProvider>
            </RecoilRoot>
          </ThemeProvider>
        </AnalyticsProvider>
      </BrowserRouter>
    </CookiesProvider>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
