import { Add as AddIcon } from '@mui/icons-material';
import {
  Box,
  Collapse,
  Fab,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import type { ListActions } from 'react-use/lib/useList';
import { v4 } from 'uuid';
import { ColorFilterIcon, ColorFilterState } from '.';
import { ColorFilterEditionCard } from './ColorFilterEditionCard';
import { getRandomPaletteColor, VERTICAL_SPEED_COLORS } from './utils';
import {
  bindTrigger,
  bindMenu,
  usePopupState,
} from 'material-ui-popup-state/hooks';

type FilterType = ColorFilterState['type'];

export const ColorFilterEditionList: React.FC<{
  filters: Array<ColorFilterState>;
  actions: ListActions<ColorFilterState>;
}> = function ColorFilterEditionList({ filters, actions }) {
  const filterAddMenu = usePopupState({
    variant: 'popover',
    popupId: `colorFilter-add`,
  });

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Tooltip title="Add new filter">
          <Fab size="small" {...bindTrigger(filterAddMenu)} color="primary">
            <AddIcon />
          </Fab>
        </Tooltip>
      </Box>
      <TransitionGroup>
        {filters.map((f, idx) => (
          <Collapse key={f.id}>
            <ColorFilterEditionCard
              sx={{ mb: 1 }}
              filter={f}
              toggable={filters.length > 1}
              deletable={idx !== filters.length - 1}
              idx={idx}
              actions={actions}
              // We need to determine somehow if the filter has just been added
              initiallyExpanded={f.displayName === ''}
            />
          </Collapse>
        ))}
      </TransitionGroup>

      <Menu
        {...bindMenu(filterAddMenu)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={() => {
            filterAddMenu.close();
            actions.insertAt(0, {
              id: v4(),
              type: 'aircraftOperator',
              displayName: '',
              aircraftOperators: [],
              enabled: true,
              color: getRandomPaletteColor(),
            });
          }}
        >
          <ListItemIcon>
            <ColorFilterIcon fontSize="small" type="aircraftOperator" />
          </ListItemIcon>
          <ListItemText>Aircraft operator</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            filterAddMenu.close();
            actions.insertAt(0, {
              id: v4(),
              type: 'callsign',
              displayName: '',
              startsWith: '',
              enabled: true,
              color: getRandomPaletteColor(),
            });
          }}
        >
          <ListItemIcon>
            <ColorFilterIcon fontSize="small" type="callsign" />
          </ListItemIcon>
          <ListItemText>Callsign</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            filterAddMenu.close();
            const topColor = getRandomPaletteColor();
            const bottomColor = getRandomPaletteColor();

            actions.insertAt(0, {
              id: v4(),
              type: 'altitudeGradient',
              displayName: 'Ground / >30,000ft',
              bottomFt: 0,
              topFt: 30000,
              aboveMode: 'exclude',
              belowMode: 'clamp',
              enabled: true,
              color: bottomColor,
              topColor,
              bottomColor,
            });
          }}
        >
          <ListItemIcon>
            <ColorFilterIcon fontSize="small" type="altitudeGradient" />
          </ListItemIcon>
          <ListItemText>Altitude</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            filterAddMenu.close();
            const color = VERTICAL_SPEED_COLORS.level;
            const climbColor = VERTICAL_SPEED_COLORS.climb;
            const descentColor = VERTICAL_SPEED_COLORS.descend;

            actions.insertAt(0, {
              id: v4(),
              type: 'verticalSpeed',
              displayName: 'Vertical speed',
              enabled: true,
              color,
              climbColor,
              descentColor,
            });
          }}
        >
          <ListItemIcon>
            <ColorFilterIcon fontSize="small" type="verticalSpeed" />
          </ListItemIcon>
          <ListItemText>Vertical speed</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
