import { Typography, DialogContentText, Divider } from '@mui/material';

export const CookieInfo: React.FC<{}> = function CookieInfo() {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        Cookies we use
      </Typography>
      <Typography variant="h6" gutterBottom>
        Strictly necessary cookies
      </Typography>
      <DialogContentText>
        These cookies are necessary for the website to function and cannot be
        switched off in our systems. They are usually only set in response to
        actions made by you which amount to a request for services, such as
        setting your privacy preferences, logging in or filling in forms. You
        can set your browser to block or alert you about these cookies, but some
        parts of the site will not then work. These cookies do not store any
        personally identifiable information.
      </DialogContentText>

      <Divider sx={{ mt: 2, mb: 2 }} />
      <Typography variant="h6" gutterBottom>
        Performance Cookies
      </Typography>
      <DialogContentText gutterBottom>
        These cookies allow us to count visits and traffic sources so we can
        measure and improve the performance of our site. They help us to know
        which pages are the most and least popular and see how visitors move
        around the site. All information these cookies collect is aggregated and
        therefore anonymous. If you do not allow these cookies we will not know
        when you have visited our site, and will not be able to monitor its
        performance.
      </DialogContentText>
      <Typography>Tools used:</Typography>
      <ul>
        <li>Google Analytics</li>
      </ul>
    </>
  );
};
