import { useCallback, useMemo } from 'react';
import { tuple } from '../utils';
import { useCookies } from 'react-cookie';

export function useCookieConsent() {
  const [cookie, setCookie, resetCookie] = useCookies(['cookie-consent']);

  const accept = useCallback(() => {
    console.log('[useCookieConsent]: Accepting cookies');
    setCookie('cookie-consent', true, { maxAge: 24 * 3600 * 365 });
  }, [setCookie]);

  const reset = useCallback(() => {
    console.log('[useCookieConsent]: Resetting cookie consent');
    resetCookie('cookie-consent');
  }, [resetCookie]);

  const rawConsent = cookie?.['cookie-consent'];
  const consent = useMemo(() => {
    if (!rawConsent) {
      return null;
    }

    if (rawConsent === 'true') {
      return true;
    }

    return false;
  }, [rawConsent]);

  return tuple({ consent }, { accept, reset });
}
