import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from '@mui/material/styles';
import { indigo, deepOrange } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: indigo,
    secondary: deepOrange,
  },
});

export const ThemeProvider: React.FC<{}> = function ThemeProvider({
  children,
}) {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};
