/**
 * This whole file has been adapter from react-share.
 *
 * https://github.com/nygardk/react-share/blob/master/src/ShareButton.tsx
 *
 * https://github.com/bradvin/social-share-urls helped a great deal too
 */

export function openWindow(url: string): Window | null {
  const width = 550;
  const height = 450;

  const config = {
    height,
    width,
    top: (window.screen.height - height) / 2,
    left: (window.screen.width - width) / 2,
    // location: 'no',
    // toolbar: 'no',
    status: 'no',
    directories: 'no',
    menubar: 'no',
    scrollbars: 'yes',
    resizable: 'no',
    centerscreen: 'yes',
    chrome: 'yes',
  };

  const shareDialog = window.open(
    url,
    '',
    Object.entries(config)
      .map(([key, value]) => `${key}=${value}`)
      .join(', '),
  );

  return shareDialog;
}

type ShareMetadata = { text: string; url: string; hashtags?: Array<string> };

export function createUrl(
  socialMedia: 'twitter' | 'facebook' | 'linkedin' | 'reddit',
  opts: ShareMetadata,
): string {
  switch (socialMedia) {
    case 'twitter':
      return twitter(opts);
    case 'facebook':
      return facebook(opts);
    case 'linkedin':
      return linkedin(opts);
    case 'reddit':
      return reddit(opts);
  }
}

function twitter({ text, hashtags, url }: ShareMetadata): string {
  const init = [
    ['text', text],
    ['url', url],
  ];

  if (hashtags && hashtags.length > 0) {
    init.push(['hashtags', hashtags.join(',')]);
  }

  const searchParams = new URLSearchParams(init);

  const shareUrl = new URL('https://twitter.com/intent/tweet');
  shareUrl.search = searchParams.toString();

  return shareUrl.toString();
}

function facebook({ text, hashtags, url }: ShareMetadata): string {
  const init = [
    ['quote', text],
    ['display', 'popup'],
    ['u', url],
  ];

  if (hashtags && hashtags.length > 0) {
    init.push(['hashtag', hashtags.map((h) => `#${h}`).join(',')]);
  }

  const searchParams = new URLSearchParams(init);

  const shareUrl = new URL('https://facebook.com/sharer.php');
  shareUrl.search = searchParams.toString();

  console.log(shareUrl.toString());

  return shareUrl.toString();
}

function linkedin({ text, hashtags, url }: ShareMetadata): string {
  const init = [['url', url]];

  const searchParams = new URLSearchParams(init);

  const shareUrl = new URL('https://www.linkedin.com/sharing/share-offsite/');
  shareUrl.search = searchParams.toString();

  return shareUrl.toString();
}

function reddit({ text, hashtags, url }: ShareMetadata): string {
  const init = [
    ['url', url],
    ['title', text.split('\n')[0]],
  ];

  const searchParams = new URLSearchParams(init);

  const shareUrl = new URL('https://reddit.com/submit');
  shareUrl.search = searchParams.toString();

  return shareUrl.toString();
}
